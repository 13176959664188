import React, { useEffect, useState } from "react";
import "./TextBox.css";
const TextBox = ({
  label,
  isRequired,
  labelStyle = {},
  placeholderText = "",
  value = "",
  onTextChange,
  ParentFieldWrapper={},
  errormsg,
  isCounterRequired,
  maxLength,
}) => {
  

  return (
    <div style={{width:"100%"}}>
      {label ? (
        <label className="defaultLabelStyle" style={labelStyle}>
          {label}
          {isRequired ? (
            <span style={{ color: "#BC0000", fontWeight: "bold" }}>{"*"}</span>
          ) : (
            <></>
          )}
        </label>
      ) : (
        <></>
      )}
      <div className="textboxWrapper" style={ParentFieldWrapper}>
          <textarea type="text" className="textfield"  value={value} style={{resize:"none"}}
          onChange={onTextChange}
          placeholder={placeholderText}
           maxLength={maxLength}
          />
      </div>
      <div style={{display:"flex",justifyContent:"space-between",fontSize:"12px",color:"#9D9D9D",marginTop:"3px"}}>
        <small className='errormsgStyle'>{errormsg}</small>
        { isCounterRequired==true && <small style={{fontSize:"12px"}}>{value.length}/{maxLength}</small>}
      </div>
    </div>
  );
};

export default TextBox;
