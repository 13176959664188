import React, { useEffect } from 'react'
import '../ReportsDashboard/reportsDashboard.css'
import { useSelectedCardContext } from '../../../Context/Context';
import { useNavigate } from 'react-router-dom';
import breadCrumbArrow from '../../../assets/backArrowGray.svg'

const ReportsDashboard = () => {
  const navigate = useNavigate();

  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  useEffect(()=>{
   setSelectedCard(6)
  },[])

  return (
    <div >
       <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: ' 5px 13px 0',marginTop:0}}>
        <p className="breadCrumbDiv1p" style={{marginBottom:"5px"}}>Reports Dashboard</p>
        <ul className="breadCrumb">
        <li className="breadcrumbDiv2"><img className="breadcrumbDiv2Img" src={breadCrumbArrow} alt="" />
        <span onClick={()=>navigate(-1)}>Reports Dashboard</span>
        </li>
        </ul>
      </div>
      <div className='ReportsDashboardCardsDiv'>
      <div className='ReportCards' onClick={()=>navigate('/adminDashboard/management/reportdashboard/job-posting-summary-across-company-report')}>
       <span>Job Postings per Organization</span>
      </div>
      <div className='ReportCards' onClick={()=>navigate('/adminDashboard/management/reportdashboard/job-posting-recruiter-summary-report')}>
       <span>Summary of Recruiter’s Postings</span>
      </div>
      <div className='ReportCards' onClick={()=>navigate('/adminDashboard/management/reportdashboard/job-posting-per-recruiter')}>
       <span>Postings Per Recruiter Report</span>
      </div>
      <div className='ReportCards' onClick={()=>navigate('/adminDashboard/management/reportdashboard/total-usage-summary')}>
       <span>Usage Overview</span>
      </div>
      </div>
    </div>
  )
}

export default ReportsDashboard