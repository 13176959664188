export const ModuleCards = {
    Dashboard: 1,
    JobRepository: 2,
    ApplicantRepository: 3,
    profile: 4,
    Notification: 5,
    Management: 6,
    AllJobs: 7,
    Report:8,
    CompanyList:9,
    CompanyJobs:10
}