import React, { useEffect, useState } from "react";
import "./App.css";
import { AuthContext, SelectedCardContext, NotificationContext, SelectedSubMenuContext, SelectedSubMenuJobContext, ProfileDropdownContext } from "./Context/Context";
import Navigator from "./Navigation/Navigator";
import { ComponentAndScreenConstant } from "./constants/componentAndScreenConstant";
import { Url } from "./EnvoirnmentSetup/APIs";
// import { SHA256 } from 'crypto-js';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState("");
  const [userDetails, setUserDetails] = useState({ loggedInRole: "" });
  const [selectedCard, setSelectedCard] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [subMenu,setSubMenu] = useState(false)
  const [subMenuColor,setSubMenuColor] = useState(0)
  const [subMenuColorMyJobs,setSubMenuColorMyJobs] = useState(0)
  const [subMenuJob,setSubMenuJob] = useState(0)
  const [dropDownVisible,setDropDownVisible] = useState(false)

  useEffect(() => {
    if(sessionStorage.getItem('token')){
    getUserDataname()
    }
   }, []);

   const getUserDataname = () => {
    let token = sessionStorage.getItem("token");
    try {
      fetch(Url.getuser, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((resp) => resp.json())
        .then((res) => {
          setUserDetails({
            ...userDetails, loggedInRole:res?.DATA?.roles[0]?.name
          })
          
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthContext.Provider value={{userDetails, setUserDetails}}>
      <SelectedCardContext.Provider value={{ selectedCard, setSelectedCard }}>
      <SelectedSubMenuContext.Provider value={{subMenu, setSubMenu, subMenuColor, setSubMenuColor}}>
      <SelectedSubMenuJobContext.Provider value={{subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs}}>
      <ProfileDropdownContext.Provider value={{dropDownVisible,setDropDownVisible}}>      
        <NotificationContext.Provider value={{notificationCount, setNotificationCount}}>
        <Navigator />
        </NotificationContext.Provider>
      </ProfileDropdownContext.Provider>
      </SelectedSubMenuJobContext.Provider>  
      </SelectedSubMenuContext.Provider>  
      </SelectedCardContext.Provider>
    </AuthContext.Provider>

    // <div style={{width:"30%", border:"1px solid red"}}>
    // <ComponentAndScreenConstant.DropdownWithSelectPicker
    // label = {"Job Title"}
    // isRequired = {true}

    // />

    // </div>
  );
}

export default App;
