import React, { useEffect, useState } from "react";
import {
  Route,
  HashRouter,
  Routes,
  Switch,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import { ComponentAndScreenConstant } from "../constants/componentAndScreenConstant";
import Register from "../screens/Auth/Register";
import "./naviator.css";
import { PortalUserRole } from "../EnvoirnmentSetup/APIs";
import { useAuthContext } from "../Context/Context";

const Navigator = () => {
  const [navigateScreen, setNavigateScreen] = useState(1);
  const { userDetails, setUserDetails } = useAuthContext();
  const [showMsg,setShowMsg] = useState(false)
  
  useEffect(()=>{
    setTimeout(()=>{
     setShowMsg(true)
    },3000)
  },[])
  
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<ComponentAndScreenConstant.Login />} />
        <Route
                      path="/register"
                      element={<ComponentAndScreenConstant.Register />}
                    />

        {/* ========================= Common Routes =========================== */}
        <Route path="/login" element={<ComponentAndScreenConstant.Login />} />
        {/* <Route
          path="/homepage"
          element={<ComponentAndScreenConstant.LandingPage />}
        /> */}
        
{ userDetails?.loggedInRole == "ROLE_ADMIN" ?
        <Route
          path="/adminDashboard/*"
          element={
            <div className="pDashOuterDiv">
              <div className="pDashSidebar">
                <ComponentAndScreenConstant.Sidebar
                  navigateScreen={navigateScreen}
                  setNavigateScreen={setNavigateScreen}
                />
              </div>
              <div className="pDashContent" >
                <div className="pDashHeader">
                  <ComponentAndScreenConstant.DashBoardHeader
                    navigateScreen={navigateScreen}
                  />
                </div>
                <div className="pDashBottomContent">
                  <Routes>
                    <Route
                      path="/"
                      element={<ComponentAndScreenConstant.Dashboard />}
                    />
                    <Route
                      path="/createjobs"
                      element={<ComponentAndScreenConstant.CreateJob />}
                    />
                    <Route
                      path="/editjobs"
                      element={<ComponentAndScreenConstant.CreateJob />}
                    />
                    <Route
                      path="/joblist"
                      element={<ComponentAndScreenConstant.EditJob />}
                    />
                    
                    <Route
                      path="/joblist/appliedby"
                      element={<ComponentAndScreenConstant.ApplicantAction />}
                    />
                    <Route
                      path="/joblist/viewdby"
                      element={<ComponentAndScreenConstant.ApplicantAction />}
                    />
                    <Route
                      path="/joblist/sentto"
                      element={<ComponentAndScreenConstant.ApplicantAction />}
                    />
                    <Route
                      path="/applicantrepository"
                      element={
                        <ComponentAndScreenConstant.ApplicantRepository />
                      }
                    />
                    <Route
                      path="/notification"
                      element={<ComponentAndScreenConstant.Notification />}
                    />
                    <Route
                      path="/notification/appliedby"
                      element={<ComponentAndScreenConstant.ApplicantAction />}
                    />
                    <Route
                      path="/profile"
                      element={<ComponentAndScreenConstant.UserProfile />}
                    />
                    <Route
                      path="/jobDetails"
                      element={<ComponentAndScreenConstant.JobDetails />}
                    />
                    {PortalUserRole=="ROLE_MANAGER" && <Route
                      path="/management"
                      element={<ComponentAndScreenConstant.ManagementLandingPage />}
                    />}
                    <Route
                      path="/management/alljobs"
                      element={<ComponentAndScreenConstant.EditTeamsJob />}
                    />
                    <Route
                      path="/management/alljobs/recruiterprofile"
                      element={<ComponentAndScreenConstant.ProfileTeammate />}
                    />
                    <Route
                      path="/management/userManagement"
                      element={<ComponentAndScreenConstant.UserManagement />}
                    />
                    <Route
                      path="/management/reportdashboard"
                      element={<ComponentAndScreenConstant.ReportsDashboard />}
                    />
                    <Route
                      path="/management/reportdashboard/job-posting-recruiter-summary-report"
                      element={<ComponentAndScreenConstant.JobPostingRecruiterSummaryReport />}
                    />
                    <Route
                      path="/management/reportdashboard/job-posting-summary-across-company-report"
                      element={<ComponentAndScreenConstant.JobPostingSummaryAcrossCompanyReport />}
                    />
                     <Route
                      path="/management/reportdashboard/job-posting-per-recruiter"
                      element={<ComponentAndScreenConstant.JobPostingPerRecruiter />}
                    />
                    <Route
                      path="/total-usage-summary"
                      element={<ComponentAndScreenConstant.TotalUsageSummaryReport />}
                    />
                    <Route
                      path="/total-usage-summary"
                      element={<ComponentAndScreenConstant.TotalUsageSummaryReport />}
                    />
                    <Route
                      path="/job/hired"
                      element={<ComponentAndScreenConstant.ShortListed />}
                    />
                    <Route
                      path="/companyList"
                      element={<ComponentAndScreenConstant.CompanyList />}
                    />
                    <Route
                      path="/companyProfile"
                      element={<ComponentAndScreenConstant.CompanyProfile />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          }
        />:
        <Route
        path="/*"
        element={showMsg && <h3 style={{textAlign:"center"}}>401 : <span style={{color:"#007749"}}>Unauthorised Access</span></h3>}
        />
        }
        <Route
        path="/*"
        element={<h3 style={{textAlign:"center"}}>401 : <span style={{color:"#007749"}}>Unauthorised Access</span></h3>}
        />
      </Routes>
    </HashRouter>
  );
};

export default Navigator;
