import React, { useEffect, useState } from "react";
import "./ApplicantAction.css";
import { useLocation, useNavigate } from "react-router-dom";
import skillsLogo from "../../assets/skills.svg";
import experienceLogo from "../../assets/exp.svg";
import calenderLogo from "../../assets/cal.svg";
import callIcon from "../../assets/call.svg";
import linkedinIcon from "../../assets/linkedIn.svg";
import mailIcon from "../../assets/mail.svg";
import msgIcon from "../../assets/msg.svg";
import deleteIcon from "../../assets/delete.png";
import { Url } from "../../EnvoirnmentSetup/APIs";
import moment from "moment";
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import blueBag from "../../assets/bag.svg";
import blueCalender from "../../assets/viewLocation.svg";
import blueLocation from "../../assets/locationIcon.svg";
import breadCrumbArrow from '../../assets/backArrowGray.svg'
import searchLogo from '../../assets/searchLogo.svg'
import userLogo from '../../assets/userNewLogo.svg'
import Suitcase from '../../assets/blueBag.svg'
import noRecordFound from '../../assets/noDataNew.svg'
import confirmationLogo from '../../assets/confirmLogo.jpg'
import { TiTick } from "react-icons/ti";
import { FaCircleCheck } from "react-icons/fa6";
import calendlyIcon from '../../assets/calendly.svg'
import qUpdate from '../../assets/QUpdate.svg'
import qSubmit from '../../assets/QSubmit.svg'

const ApplicantAction = () => {
  const navigate = useNavigate();

  const [Applicants, setApplicants] = useState([]);
  const [Applicants1, setApplicants1] = useState([]);
  const [jobListByDateApplied, setJobListByDateApplied] = useState([]);
  const [jobListByExperience, setJobListByExperience] = useState([]);
  const [jobListByKeywords, setJobListByKeywords] = useState([]);
  const [deletemodalIsOpen, setDeletemodalIsOpen] = useState(false);
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [FailmodalIsOpen1, setFailmodalIsOpen1] = useState(false);
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [searchValue,setSearchValue] = useState("")
  const [idToDelete, setIdToDelete] = useState({
    userId: "",
    jobId: "",
  });
  const [alertPopup,setAlertPopup] = useState(false)
  const [alertMsg,setAlertMsg] = useState(false)
  const [viewedCount, setViewedCount] = useState({
    registeredUsers:0,
    unRegisteredUsers:0
  })
  const [trackRecordModal,setTrackRecordModal] = useState(false)
  const [trackaRecordData, setTrackaRecordData] = useState({
    userId:"",
    userName:"",
    keyWord:"",
    jobRole:"",
    AppliedDate:"",
    record:""
  });
  const [confirmationPopup,setConfirmationPopup] = useState(false)
  const [confirmShortlistPopup,setConfirmShortlistPopup] = useState(false)
  const [jobID,setJobID] = useState()
  const [userID,setuserID] = useState()
  const [fetchApplicants,setFetchApplicants] = useState(false)


  let propData = useLocation();
  // console.log({ propData });
  let ScreenName = propData?.state.flow;
  let JobId = propData.state.jobId;
  console.log({ ScreenName });

  let JobDetails = propData.state.jobDetails;
  console.log({ JobDetails });
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      maxWidth:"85%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  useEffect(() => {
    GetApplicantList();
  }, [fetchApplicants]);

  const trackRecordStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width:window.screen.width>1024?"50%":"90%",
      borderRadius: "25px",
      padding: "10px",
    },
  };

  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "25px",
      maxWidth:"85%"
    },
  };

  const ApplictionActionloaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  const ApplictionActioncustomStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      maxWidth:"80%",
      borderRadius: "25px",
      padding: "10px",
    },
  };

  function openDeleteModal() {
    setDeletemodalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeletemodalIsOpen(false);
  }
  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  function openSuccessModal() {
    setSuccessModalIsOpen(true);
  }

  function closeSuccessModal() {
    setSuccessModalIsOpen(false);
  }

  const GetApplicantList = async () => {
    setFetching(true)
    let token = sessionStorage.getItem("token");
    let jobData;
    if (ScreenName == "Applied") {
      jobData = {
        intrested: true,
        job: {
          jobId: JobId,
        },
      };
    } else if (ScreenName == "Viewed") {
      jobData = {
        viewed: true,
        job: {
          jobId: JobId,
        },
      };
    } else if (ScreenName == "Sent to") {
      jobData = {
        sendTo: true,
        job: {
          jobId: JobId,
        },
      };
    }
    // console.log(APIURL.GetApplyiedApplicantList);
    try {
      fetch(Url?.GetApplyiedApplicantList, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(jobData),
      })
        .then((response) => response.json())
        .then((res) => {
          setFetching(false)
          if (res.SUCCESS) {
            let changedData = res?.DATA.map((i) => {
              let stateString = "";
              i?.job?.state?.map((j, index) => {
                if (index < i.state?.length - 1) {
                  stateString = stateString + j.stateName + ", ";
                } else {
                  stateString = stateString + j.stateName;
                }
              });
              let designationString = "";
              i?.job?.designation?.map((j, index) => {
                if (index < i.designation?.length - 1) {
                  designationString =
                    designationString + j.designationName + ", ";
                } else {
                  designationString = designationString + j.designationName;
                }
              });
              let keywordsString = "";
              i?.skill?.map((j, index) => {
                if (index < i?.skill?.length - 1) {
                  keywordsString = keywordsString + j?.name + ", ";
                } else {
                  keywordsString = keywordsString + j?.name;
                }
              });
              return { ...i, stateString, designationString, keywordsString };
            });
            // console.log(
            //   "Job details applicant12345678",
            //   JSON.stringify(changedData)
            // );

            /*  const compareDatesApplied = (a, b) => moment(a?.appliedDate).diff(moment(b?.appliedDate));
                    const sortedArrayApplied = arr2.sort(compareDatesApplied);
                    console.log("Sort by Applied date", JSON.stringify(sortedArrayApplied)) */
                    let arr2 = [...changedData]


                    setJobListByDateApplied(arr2);

            const copiedJobDataArray = [...changedData];
            const sortedJobDataArray1 = copiedJobDataArray.sort(
              (a, b) =>
                b?.experience?.experienceId - a?.experience?.experienceId
            );
            // console.log(
            //   "Sort by Experience",
            //   JSON.stringify(sortedJobDataArray1)
            // );
            setJobListByExperience(sortedJobDataArray1);

            const copiedJobDataArray2 = [...changedData];
            // console.log(
            //   "copiedJobDataArray2copiedJobDataArray2",
            //   copiedJobDataArray2
            // );
            const sortedJobDataArray2 = copiedJobDataArray2.sort(
              (a, b) => b?.skill.length - a?.skill.length
            );
            setApplicants(sortedJobDataArray2);
            setJobListByKeywords(sortedJobDataArray2);
            // setselectedFilter(1);
            setViewedCount({
              registeredUsers:res?.NOTE?.applicantCount?.viewd,
              unRegisteredUsers:res?.NOTE?.applicantCount?.unregisteredViews
            })
          }
        });
    } catch (error) {
      console.log("Job applicant", error);
      setFetching(false)
    } finally {
      //   setIsLoading(false);
    }
  };

  const handleClick = (link, type) => {
    // Change the location to Google.com
    if (type == "mail") {
      link = `mailto:${link}`;
    } else if (type == "whatsapp") {
      link = "https://wa.me/" + link;
    }
    window.open(link);
  };
  const openLinkedInProfile = async (linkedinUsername, userId) => {
    let token = sessionStorage.getItem("token");

    // let linkedinUsername = 'example'; // Replace with the LinkedIn username or profile ID you want to open
    try {
      let PositionStatus = {
        recruiterView: true,
        user: {
          userId: userId,
        },
        job: {
          jobId: JobId,
        },
      };
      console.log("open linkedin", PositionStatus);

      fetch(Url?.ChangeJobPostStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(PositionStatus),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            handleClick(linkedinUsername);
          }
        });
    } catch (error) {
      console.log("called applicant err", error);
    }
  };

  const openMail = async (mailLink, userId) => {
    let token = sessionStorage.getItem("token");

    // let linkedinUsername = 'example'; // Replace with the LinkedIn username or profile ID you want to open
    try {
      let PositionStatus = {
        recruiterContacted: true,
        user: {
          userId: userId,
        },
        job: {
          jobId: JobId,
        },
      };
      console.log("open linkedin", PositionStatus);

      fetch(Url?.ChangeJobPostStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(PositionStatus),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            handleClick(mailLink, "mail");
          }
        });
    } catch (error) {
      console.log("called applicant err", error);
    }
  };

  const callTheApplicant = async (cntryCode,mobileumber, userId) => {
    let token = sessionStorage.getItem("token");

    // let linkedinUsername = 'example'; // Replace with the LinkedIn username or profile ID you want to open
    try {
      let PositionStatus = {
        recruiterContacted: true,
        user: {
          userId: userId,
        },
        job: {
          jobId: JobId,
        },
      };
      console.log("open linkedin", PositionStatus);

      fetch(Url?.ChangeJobPostStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(PositionStatus),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            setAlertMsg("Mobile number of applicant is " + "+" + cntryCode + " " +mobileumber)
            setAlertPopup(true)
          }
        });
    } catch (error) {
      console.log("called applicant err", error);
    }
  };

  const openWhatsapp = async (whatsappNumber, userId) => {
    let token = sessionStorage.getItem("token");

    // let linkedinUsername = 'example'; // Replace with the LinkedIn username or profile ID you want to open
    try {
      let PositionStatus = {
        recruiterContacted: true,
        user: {
          userId: userId,
        },
        job: {
          jobId: JobId,
        },
      };
      console.log("open linkedin", PositionStatus);

      fetch(Url?.ChangeJobPostStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(PositionStatus),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            handleClick(whatsappNumber, "whatsapp");
          }
        });
    } catch (error) {
      console.log("called applicant err", error);
    }
  };

  const DeleteJob = (userId, jobId) => {
    setIdToDelete({
      userId: userId,
      jobId: jobId,
    });
    openDeleteModal();
  };

  const handleDeleteApplication = async () => {
    closeDeleteModal();
    let token = sessionStorage.getItem("token");
    let PositionStatus = {
      closed: true,
      user: {
        userId: idToDelete?.userId,
      },
      job: {
        jobId: idToDelete?.jobId,
      },
    };
    fetch(Url?.ChangeJobPostStatus, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(PositionStatus),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log({ res });
        if (res?.SUCCESS) {
          GetApplicantList();
          setsuccessMsg(
            `The Application from this applicant is deleted successfully!`
          );
          openSuccessModal();
        } else {
          let originalString = res?.message;
          if(originalString.includes(':')){
            let resultString = originalString
            .split(":")
            .slice(1)
            .join(":")
            .trim();
          setError(resultString);
          }else{
            setError(originalString);
          }
          openFailModal();
        }
      });
  };

  useEffect(()=>{
    setApplicants(jobListByDateApplied.filter((item)=>{
      return(
        ((item?.fullName.toString()).toLowerCase()).includes(searchValue.toLowerCase())||
        ((item?.experience?.experience.toString()).toLowerCase()).includes(searchValue.toLowerCase())||
        ((item?.keywordsString.toString()).toLowerCase()).includes(searchValue.toLowerCase())
      )
    }))
  },[searchValue])

  const handleTrackRecords = (item) => {
    console.log("lflflf",item?.recruiterNote,!!item?.recruiterNote)
    setTrackaRecordData({
      userId:item?.userId,
      userName:item?.fullName,
      keyWord:item?.keywordsString,
      jobRole:item?.designationString,
      AppliedDate:moment(item?.appliedDate).format("DD-MMM-YYYY"),
      record: !!item?.recruiterNote ? item?.recruiterNote : "",
      education: !!item?.education ? item?.education : "",
      noticePeriod  : !!item?.noticePeroid? item?.noticePeroid  :"",
      currentCTC : !!item?.currentCTC? item?.currentCTC  :"",
      expectedCTC : !!item?.expectedCTC? item?.expectedCTC : ""
    })
     setTrackRecordModal(true)
  }


  const setDiscussionData = async () => {
    // let linkedinUsername = 'example'; // Replace with the LinkedIn username or profile ID you want to open
   if(!!trackaRecordData.record){ 
    try {
      let token = sessionStorage.getItem("token");
      let DisscussionData = {
        recruiterNote: trackaRecordData?.record,
        user: {
          userId: trackaRecordData?.userId,
        },
        job: {
          jobId: JobId,
        },
        currentCTC:trackaRecordData?.currentCTC,
        expectedCTC:trackaRecordData?.expectedCTC,
        education:trackaRecordData?.education,
        noticePeroid:trackaRecordData?.noticePeriod
      };
      console.log("DisscussionData", DisscussionData);

      fetch(Url?.ChangeJobPostStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(DisscussionData),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            setsuccessMsg(
              `The track record updated successfully!`
            );
            openSuccessModal();
            setTrackRecordModal(false)
            GetApplicantList();

          }else{
            let originalString = res?.message;
          if(originalString.includes(':')){
            let resultString = originalString
            .split(":")
            .slice(1)
            .join(":")
            .trim();
          setError(resultString);
          }else{
            setError(originalString);
          }
          setTrackRecordModal(false)
          setFailmodalIsOpen1(true)
          }
        });
    } catch (error) {
      console.log("called applicant err", error);
    }
  }
  else{
    setConfirmationPopup(true)
  }
  };

  const handleConfirmSubmit = () => {
    try {
      let token = sessionStorage.getItem("token");
      let DisscussionData = {
        recruiterNote: trackaRecordData?.record,
        user: {
          userId: trackaRecordData?.userId,
        },
        job: {
          jobId: JobId,
        },
        currentCTC:trackaRecordData?.currentCTC,
        expectedCTC:trackaRecordData?.expectedCTC,
        education:trackaRecordData?.education,
        noticePeroid:trackaRecordData?.noticePeriod
      };
      console.log("DisscussionData", DisscussionData);

      fetch(Url?.ChangeJobPostStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(DisscussionData),
      })
        .then((response) => response.json())
        .then((res) => {
          setConfirmationPopup(false)
          if (res.SUCCESS) {
            setsuccessMsg(
              `The track record updated successfully!`
            );
            openSuccessModal();
            setTrackRecordModal(false)
            GetApplicantList();

          }else{
            let originalString = res?.message;
          if(originalString.includes(':')){
            let resultString = originalString
            .split(":")
            .slice(1)
            .join(":")
            .trim();
          setError(resultString);
          }else{
            setError(originalString);
          }
          openFailModal();
          }
        });
    } catch (error) {
      console.log("called applicant err", error);
      setConfirmationPopup(false)
    }
  }

  const ShortListCandidate = () => {
    let obj = {
      job: {
          jobId: jobID
      },
      selected: true,
      user: {
          userId: userID
      }
  }
  setConfirmShortlistPopup(false)
      try{
        setFetching(true)
        fetch(Url?.shortListCandidate, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem('token'),
          },
          body: JSON.stringify(obj),
        }).then((resp)=>resp.json())
        .then((res)=>{
          setFetching(false)
          if(res?.SUCCESS==true){
            setsuccessMsg("Candidate Hired Successfully.")
            setSuccessModalIsOpen(true)
            setFetchApplicants(!fetchApplicants)
          }
          else{
            setError(res?.message)
            setFailmodalIsOpen(true)
          }
        })
        .catch((e)=>{
          console.log(e);
          setFetching(false)
        })
      }
      catch(e){
        console.log(e);
        setFetching(false)
      }
  }
  const handleSort = (val)=>{
    console.log(val);
    if(val=="Sort by Keywords"){
    setApplicants([...jobListByKeywords])
    }
    else if(val == "Sort by Experience"){
      setApplicants([...jobListByExperience])
    }
    else if (val == "Sort by Applied Date"){
      setApplicants([...jobListByDateApplied])
    }
  }
  
  const handleInputChange = (e,field) => {
    if(field=="Current CTC" && /^(?!\.)\d*\.?\d*$/.test(e?.target?.value)){
      setTrackaRecordData({...trackaRecordData,currentCTC:e?.target?.value})
      }else if(field =="Expected CTC" && /^(?!\.)\d*\.?\d*$/.test(e?.target?.value)){
        setTrackaRecordData({...trackaRecordData,expectedCTC:e?.target?.value})
      }
      else if(field=="Notice Period"){
        if(/^\d*$/.test(e?.target?.value)){
        setTrackaRecordData({...trackaRecordData,noticePeriod:e?.target?.value})
        }
      }
      else if(field == "Education"){
        if(/^[a-zA-Z:;.,/()\-\s]*$/.test(e?.target?.value)){
          setTrackaRecordData({...trackaRecordData,education:e?.target?.value})
        }
      }
  }
  return (
    <div className="ApplicantActionContainerWrapper">
      <div className="ApplicantActionContainerTitleWrapper">
        <p className="ApplicantActionPara">{ScreenName +"-"+ " "+ JobDetails?.designation[0]?.designationName}</p>
        <ul className="breadCrumb" style={{fontSize:"14px"}}>
          <li style={{color:"#007749"}}>{propData?.state?.flow.toLowerCase() == "applied"? "Applied" : propData?.state?.flow.toLowerCase() == "viewed"? "Viewed by" : "Sent To"}</li> 
          <img style={{width:"8px"}} src={breadCrumbArrow} alt="" />
          {propData?.state?.showJobDetailBreadCrumb != false? <li style={{cursor:"pointer"}} onClick={()=>navigate(-1)}>Job Details</li> : <></>}
          {propData?.state?.showJobDetailBreadCrumb != false?<img style={{width:"8px"}} src={breadCrumbArrow} alt="" />: <></>}
          <li style={{cursor:"pointer"}}onClick={()=>{ if(propData?.state?.showJobDetailBreadCrumb!=false){navigate(-2)}else{navigate(-1)}}}>{propData?.state?.breadCrumbVisible == "Company Jobs"? "All Jobs":"My Postings"}</li>
        </ul>
      </div>

      <div className="mainWrapperAppliedFor">
        <div className="showAppliedForWrapper">
          <div style={{display:"flex",width:"55%",justifyContent:"space-around"}}>
          <div className="innerInfoWrapper">
            <div>
              <img src={blueBag} className="SuitcaseLogo" />
            </div>
            <div className="innerTextWrapper">
              {JobDetails?.experience?.experience}
            </div>
          </div>

          <div className="innerInfoWrapper">
            <div>
              <img src={blueCalender} className="calenderLogo" />
            </div>
            <div className="innerTextWrapper">
              Active Till: {moment(JobDetails?.tillDate).format("DD-MMM-YYYY")}
            </div>
          </div>

          <div className="innerInfoStateWrapper">
            <div>
              <img src={blueLocation} className="LocationLogo" />
            </div>
            <div className="innerStateTextWrapper">
              {JobDetails?.state?.map((v)=>v?.stateName).join(', ')}
            </div>
          </div>
          </div>
          <div className="searchAndSortWrapper">
            <div className="appcntActionSearchDiv">
              <div className="appcntActionSearchImgDiv">
                <img src={searchLogo}/>
              </div>
              <input type="text" value={searchValue} onChange={(e)=>setSearchValue(e?.target?.value)} placeholder="Search here..." className="appcntActionSearchInput"/>
            </div>
            <select className="appcntActionSelect" onChange={(e)=>handleSort(e.target.value)}>
            <option onClick={()=>{setApplicants(jobListByKeywords)}}>Sort by Keywords</option>
            {ScreenName == "Applied" && <option onClick={()=>{setApplicants(jobListByDateApplied)}}>Sort by Applied Date</option>}
            <option onClick={()=>{setApplicants(jobListByExperience)}}>Sort by Experience</option>
            </select>
          </div>
        </div>
      </div>
      {ScreenName == "Viewed" && 
      <div className="regAndNonRegUsers">
        {/* <span>Registered Users - {viewedCount?.registeredUsers}</span> */}
        {/* <span>Non-registered Users - {viewedCount?.unRegisteredUsers}</span> */}
        <span className="regAndNonRegUsersSpan">
          <span>{viewedCount?.registeredUsers!=1?"Registered Users":"Registered User"}</span>
          <span>{viewedCount?.registeredUsers}</span>
        </span>
        <span className="regAndNonRegUsersSpan">
          <span>{viewedCount?.unRegisteredUsers!=1?"Non-Registered Users":"Non-Registered User"}</span>
          <span>{viewedCount?.unRegisteredUsers}</span>
        </span>
      </div>}
      <div className="ApplicantActionContainerParent">
        <div className={ScreenName =="Applied"?"ApplicantActionContainer applicantActionContainerNew":"ApplicantActionContainer"}>
          { Applicants.length>0?
          Applicants?.map((i, index) => {
            console.log("name", i);
            return (
              ScreenName !== "Applied"? 
              <>
                <div className="ApplicantDetailscard" key={index}>
                {ScreenName !== "Applied" && <div className="ApplicantDetailsBorderLeftDiv"></div>}
                  {ScreenName == "Applied" && (
                    <div
                      className="deleteSection"
                      onClick={() => {
                        DeleteJob(i?.userId, i?.job?.jobId);
                      }}
                    >
                      <img src={deleteIcon} style={{cursor:"pointer"}} className="deleteIconStyle" />
                    </div>
                  )}
                  <div className="ApplicantDetailswrapper">
                    <p className="applicantName">{i?.fullName}</p>
                    <div className="skillWrapper">
                      <img src={skillsLogo} className="applicantSkillLogo" />
                      <p>
                        {!!i?.keywordsString
                          ? i?.keywordsString
                          : "No Keywords matched"}
                      </p>
                    </div>
                    <div className="skillWrapper">
                      <img src={experienceLogo} className="applicantSkillLogo" />
                      <p>{i?.experience?.experience}</p>
                    </div>
                    <div className="lastRowWrapper">
                      {/* {ScreenName == "Applied" &&<button className="trackRecordsBtn" onClick={()=>handleTrackRecords(i)}>Track Records</button>} */}
                      <div className="skillWrapper2">
                        {!!i?.appliedDate ? (
                          <>
                            <img
                              src={calenderLogo}
                              className="applicantSkillLogo"
                            />
                            <p>{moment(i?.appliedDate).format("DD-MMM-YYYY")}</p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="contactIcons">
                        <img
                          src={callIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={() =>
                            callTheApplicant(
                              i?.countryCode?.countryCode , i?.mobileNumber,
                              i?.userId
                            )
                          }
                        />
                        <img
                          src={linkedinIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={() =>
                            openLinkedInProfile(i?.linkedinProfile, i?.userId)
                          }
                        />
                        <img
                          src={mailIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={() => openMail(i?.userName, i?.userId)}
                        />
                        <img
                          src={msgIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={() =>
                            openWhatsapp(
                              i?.countryCode?.countryCode + i?.mobileNumber,
                              i?.userId
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </> :

              <>
                <div className="ApplicantDetailscardNew" key={index}>
                {ScreenName !== "Applied" && <div className="ApplicantDetailsBorderLeftDiv"></div>}
                  {ScreenName == "Applied" && (
                   i.selected!=true?<div
                      className="deleteSection"
                      title={i.selected==true?"Hired candidates can't be deleted.":"Delete applicant."}
                      onClick={() => {
                       i.selected!=true && DeleteJob(i?.userId, i?.job?.jobId);
                      }}
                    >
                      <img src={deleteIcon} style={{cursor:"pointer"}} className="deleteIconStyle" />
                    </div>:
                    <div className="shortListedSection">
                      <FaCircleCheck/>&nbsp;Hired 
                    </div>
                  )}
                  {ScreenName == "Applied" && (
                    i.selected!=true?<div
                      title={i.selected==true?"Already hired.":"Hire this candidate."}
                      className="acceptSection"
                      onClick={() => {
                        i.selected!=true && setConfirmShortlistPopup(true); setJobID(i?.job?.jobId);setuserID(i?.userId)
                      }}
                    >
                      {/* <img src={deleteIcon} style={{cursor:"pointer"}} className="deleteIconStyle" /> */}<TiTick style={{color:"#007749",cursor:"pointer",fontSize:"20px"}}/>
                    </div>:<></>
                  )}
                  <div className="appliedDetailsTRwrapper">
                  <div className="ApplicantDetailswrapper ApplicantDetailswrapperNew">
                    <p className="applicantName1">{i?.fullName}</p>
                    <div className="skillWrapper">
                      <img src={skillsLogo} className="applicantSkillLogo" />
                      <p>
                        {!!i?.keywordsString
                          ? i?.keywordsString
                          : "No Keywords matched"}
                      </p>
                    </div>
                    <div className="skillWrapper">
                      <img src={experienceLogo} className="applicantSkillLogo" />
                      <p>{i?.experience?.experience}</p>
                    </div>
                    
                    <div className="lastRowWrapper" style={{justifyContent:ScreenName == "Applied"? "space-between":"flex-end"}}>
                      {/* {ScreenName == "Applied" &&<button className="trackRecordsBtn" onClick={()=>handleTrackRecords(i)}>Track Records</button>} */}
                      <div className="skillWrapper2">
                        {!!i?.appliedDate ? (
                          <>
                            <img
                              src={calenderLogo}
                              className="applicantSkillLogo"
                            />
                            <p>{moment(i?.appliedDate).format("DD-MMM-YYYY")}</p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="contactIcons">
                        <img
                          src={callIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={() =>
                            callTheApplicant(
                              i?.countryCode?.countryCode , i?.mobileNumber,
                              i?.userId
                            )
                          }
                        />
                        <img
                          src={linkedinIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={() =>
                            openLinkedInProfile(i?.linkedinProfile, i?.userId)
                          }
                        />
                        <img
                          src={mailIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={() => openMail(i?.userName, i?.userId)}
                        />
                        <img
                          src={msgIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={() =>
                            openWhatsapp(
                              i?.countryCode?.countryCode + i?.mobileNumber,
                              i?.userId
                            )
                          }
                        />
                        {!!i?.calendlyProfile && <img
                          src={calendlyIcon}
                          style={{cursor:"pointer"}}
                          className="ContactIconslogo"
                          onClick={()=>window.open(i?.calendlyProfile,'_blank')}
                        />}
                      </div>
                    </div>
                  </div>

                  <div className="trOuterDiv" style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start"}}>
                     <div className="trDiv" style={{width:window.screen.width<1020?"95%":i.selected?"77%":"88%"}}>
                        {!!i?.recruiterNote ?
                          <textarea readOnly value={i?.recruiterNote}></textarea>
                          :
                          <div className="noRecordDivNew">
                            <img src={noRecordFound} alt=""/>
                            <span>No Records Available.</span>
                          </div>
                        }
                        
                     </div>
                     <div className="TRmodalBtnsNew" style={{borderTop:"0.5px solid gray",alignItems:"end"}}>
                          {/* <button style={{display:"none"}}>Cancel</button> */}
                          <button onClick={()=>handleTrackRecords(i)}>Add Record</button>
                          {/* {!!i?.education && <span title={i?.education} style={{maxWidth:"12%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{i?.education}</span>}
                          {!!i?.noticePeroid && <span title={i?.noticePeroid}>Notice Period: {i?.noticePeroid}</span>}
                          {!!i?.currentCTC && <span title={i?.currentCTC}>Current CTC: {i?.currentCTC}</span>}
                          {!!i?.expectedCTC && <span title={i?.expectedCTC}>Expected CTC: {i?.expectedCTC}</span>} */}
                          <div style={{display:"flex",flexDirection:"column",width:window.screen.width>1020?"80%":"100%",fontSize:"11px",justifyContent:"space-between",color:"#007749",fontWeight:"medium"}}>
                          {!!i?.education &&<div className="eduDivApplied" title={i?.education} style={{maxWidth:"95%",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",marginBottom:"3px"}}>Education: <span style={{fontWeight:"normal",color:"black"}}>{i?.education}</span></div>}
                          <div className="moreDetailsApplicant" style={{display:"flex",justifyContent:"space-between",width:"95%"}}>
                          {!!i?.noticePeroid && <span title={i?.noticePeroid}>Notice Period: <span style={{fontWeight:"normal",color:"black"}}>{i?.noticePeroid} {i?.noticePeroid == 1 ? "month":"months"}</span></span>}
                          {!!i?.currentCTC && <span title={i?.currentCTC}>Current CTC: <span style={{fontWeight:"normal",color:"black"}}>{i?.currentCTC} {i?.currentCTC? "LPA":""}</span></span>}
                          {!!i?.expectedCTC && <span title={i?.expectedCTC}>Expected CTC: <span style={{fontWeight:"normal",color:"black"}}>{i?.expectedCTC} {i?.expectedCTC? "LPA":""}</span></span>} 
                          </div>
                          <div>

                          </div>
                          </div>
                        </div>
                  </div>
                  </div>
                </div>
              </>
            );
          })
          :
          <div className="noNotificationMsg" style={{width:"100%"}}>
            <img
              src={noRecordFound}
              className="nodataImage"
            />
            <p>{!!searchValue ? "Searched results not found.": "Records are not available."}</p>
          </div>
        }
        </div>
      </div>
      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={ApplictionActionloaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
      <Modal
        isOpen={deletemodalIsOpen}
        onRequestClose={closeDeleteModal}
        ariaHideApp={false}
        style={ApplictionActioncustomStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="deleteModalWrapper">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={closeDeleteModal}>
              ×
            </p>
          </div>
        </div>
        <div className="delAccModalErrMsg">
          <p>Would you like to delete this Application?</p>
          <div>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #007749",
                backgroundColor:"#ffff"
              }}
              onClick={closeDeleteModal}
            >
              No
            </button>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #007749",
                color: "#FFF",
                backgroundColor: "#007749",
              }}
              onClick={() => {
                handleDeleteApplication();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={ApplictionActioncustomStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={closeFailModal}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeFailModal}>OK</button>
        </div>
      </Modal>
      
      <Modal
        isOpen={FailmodalIsOpen1}
        onRequestClose={()=>{setFailmodalIsOpen(false);setTrackRecordModal(true)}}
        ariaHideApp={false}
        style={ApplictionActioncustomStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={()=>{setFailmodalIsOpen(false);setTrackRecordModal(true)}}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={()=>{setFailmodalIsOpen(false);setTrackRecordModal(true)}}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={closeSuccessModal}
        ariaHideApp={false}
        style={ApplictionActioncustomStylesDeleteModal}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={closeSuccessModal} className="delAccModalCrossPara">
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{successMsg}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeSuccessModal}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={alertPopup}
        onRequestClose={()=>setAlertPopup(false)}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        {/* <div className="deleteModalWrapper"></div> */}
        <div className="delAccModalErrMsg" style={{margin:"auto"}}>
         <p>{alertMsg}</p>
          <div>
            <button
              style={{
                padding: "10px 20px",
                //margin: "10px",
                borderRadius: "5px",
                backgroundColor:"#007749",
                border:"none",
                color:"#ffff"
              }}
              onClick={()=>setAlertPopup(false)}
            >OK
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={trackRecordModal}
        onRequestClose={()=>setTrackRecordModal(false)}
        ariaHideApp={false}
        style={trackRecordStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="deleteModalWrapper">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={()=>setTrackRecordModal(false)}>
              ×
            </p>
          </div>
        </div>

        <div className="TRTitleDiv">
          <span>Track Records</span>
        </div>
        <div className="TRModalContentDiv">
         <div className="TRmodalDetails">
           <div>
              <div className="TRmodalSubDetailsOne">
                <img src={userLogo}/>
                <span>{trackaRecordData?.userName}</span>
              </div>
              <div className="TRmodalSubDetailsOne">
                <img src={experienceLogo}/>
                <span>{trackaRecordData?.jobRole}</span>
              </div>
           </div>
           <div className="TRmodalSubDetailsTwo">
             <span>Keywords: <span style={{color:"#909090"}}>{!!trackaRecordData?.keyWord ? trackaRecordData?.keyWord : "No Keywords matched"}</span></span>
             {/* <span>Total Experience: <span style={{color:"#909090"}}>3 Years</span></span> */}
             <span>Applied Date: <span style={{color:"#909090"}}>{trackaRecordData?.AppliedDate}</span></span>
           </div>
         </div>
         <div className="TREditFieldsDiv">

            <div className="TREditSubDiv">
                 <div>
                  <label>Education:</label>
                  <input placeholder="Enter education" maxLength={100} value={trackaRecordData?.education} onChange={(e)=>handleInputChange(e,'Education')}/>
                 </div>
                 <div>
                  <label>Notice Period (in months):</label>
                  <input placeholder="Enter notice period" value={trackaRecordData?.noticePeriod} maxLength={2} onChange={(e)=>handleInputChange(e,'Notice Period')}/>
                 </div>
            </div>
            <div className="TREditSubDiv">
                 <div>
                  <label>Current CTC:</label>
                  <input placeholder="Enter salary in CTC only" maxLength={4} value={trackaRecordData?.currentCTC} onChange={(e)=>handleInputChange(e,"Current CTC")}/>
                 </div>
                 <div>
                  <label>Expected CTC:</label>
                  <input placeholder="Enter salary in CTC only" maxLength={4} value={trackaRecordData?.expectedCTC} onChange={(e)=>handleInputChange(e,"Expected CTC")}/>
                 </div>
            </div>

         </div>
         <div className="TRtxtAreaDiv">
          <label>Track Records :</label>
          <textarea placeholder="Enter here" onChange={(e)=>setTrackaRecordData({...trackaRecordData, record:e.target.value})} value={trackaRecordData?.record} maxLength={500}/>
          {!(!!trackaRecordData.record) && <div className="noRecordDiv">
            <img src={noRecordFound} alt=""/>
            <span>No Records Available</span>
          </div>}
          <div style={{ width:"100%"}}>
          <p style={{padding:"0px", margin:"0px",textAlign:"right"}}>{trackaRecordData?.record?.length}/500</p>
          </div>
         </div>
         <div className="TRmodalBtns">
          <button onClick={()=>setTrackRecordModal(false)}>Cancel</button>
          <button onClick={()=>setDiscussionData()}><img src={qSubmit} alt="Something went wrong"/></button>
         </div>
        </div>

      </Modal>

      <Modal
        isOpen={confirmationPopup}
        onRequestClose={() => setConfirmationPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setConfirmationPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div className='logoutLogoDiv'>
          <img src={confirmationLogo} style={{width:"120px"}} />
        </div>
        <div className="delAccModalErrMsg" style={{ fontWeight: "normal",margin:0,padding:"10px 0 25px 0" }}>
          Are you sure you want to submit without entering value?
        </div>
        <div className="okBtnModal" style={{ display: "flex", gap: "2%" }}>
          <button  onClick={handleConfirmSubmit}>Yes</button>
          <button onClick={() => setConfirmationPopup(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #007749", color: "#007749"}}>No</button>
        </div>
      </Modal>

      <Modal
        isOpen={confirmShortlistPopup}
        onRequestClose={() => setConfirmShortlistPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setConfirmShortlistPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div className='logoutLogoDiv'>
          <img src={confirmationLogo} style={{width:"120px"}} />
        </div>
        <div className="delAccModalErrMsg" style={{ fontWeight: "normal",margin:0,padding:"10px 0 25px 0" }}>
          Are you sure you want to hire this candidate?
        </div>
        <div className="okBtnModal" style={{ display: "flex", gap: "2%" }}>
          <button onClick={()=>ShortListCandidate()}>Yes</button>
          <button onClick={() => setConfirmShortlistPopup(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #007749", color: "#007749"}}>No</button>
        </div>
      </Modal>
    </div>
  );
};

export default ApplicantAction;
