import React, { useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const SelectField = ({
  labelTxt,
  selectOptions,
  inputWrapperStyle,
  errMsg,
  onChange,
  inputId,
  menuPlacement,
  isMultiValue,
  isDisabled,
  placeholderTxt,
  selectedValue,
  isCreatable,
  isRequired = false,
  componentWidth,
  inputWidth,
  gap,
  cursor = true,
  maxSelected = 100,
  selectID
}) => {

  useEffect(()=>{
    if(selectID){
        const designationWrappers = document?.getElementById(selectID)
        const shouldHideAddMore = Object.values(selectedValue).every(value => value === null || value === '' || (Array.isArray(value) && value.length === 0));
              const inputElement = designationWrappers.querySelector('input');    
          if (inputElement) { 
            inputElement.placeholder = shouldHideAddMore || selectedValue?.length==maxSelected? '' : 'Add More';
            inputElement.style.width = '100px';
            inputElement.style.display = selectedValue?.length == maxSelected? "none" : "block"
          }
        }
},[inputWrapperStyle,selectedValue]) 

  // Check if the number of selected values is 10 or more
  const isMaxSelected = Array.isArray(selectedValue) && selectedValue.length >= maxSelected;

  const handleChange = (newValue, actionMeta) => {
    // Check if the action is "select-option" and if max limit is reached
    if (actionMeta.action === "select-option" && newValue.length > maxSelected) {
      return; // Do not update state if max limit is reached
    }
    onChange(newValue, actionMeta);
  };

  const customStyles = (isDisabled) => ({
    placeholder: (provided) => ({
      ...provided,
      color: '#807D7D !important',
      fontSize: '13px',
      cursor: cursor === false && 'not-allowed'
    }),
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#a7a7a7 !important',
      minHeight: '44px',
      width: window.screen.width > 1023 ? '100%' : inputWidth ? inputWidth : '98%',
      margin: gap ? "2px 0" : "5px 0",
      textAlign: 'left',
      boxShadow: null,
      borderRadius: '0px',
      cursor: cursor === false && 'not-allowed'
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: '44px',
      padding: '0 6px',
      textAlign: 'left',
      fontSize: '13px',
      cursor: cursor === false && 'not-allowed'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      textAlign: 'left',
      cursor: cursor === false && 'not-allowed'
    }),
    indicatorSeparator: state => ({
      display: 'none',
      borderLeft: '1px solid',
      cursor: cursor === false && 'not-allowed'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      display: isDisabled || isMaxSelected ? 'none' : 'flex',
      height: '44px',
      cursor: cursor === false && 'not-allowed'
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: "#E1F5ED",
      cursor: cursor === false && 'not-allowed'
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#000000",
      cursor: cursor === false && 'not-allowed'
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      display: isDisabled ? 'none' : 'flex',
      color: "#01774A",
      ':hover': {
        backgroundColor: "#01774A",
        color: '#fff',
      },
      transition: 'all 0.2s ease',
      cursor: cursor === false && 'not-allowed'
    }),
  });

  const skillsCustomStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#807D7D !important',
      fontSize: '13px',
      cursor: cursor === false && 'not-allowed'
    }),
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#a7a7a7 !important',
      minHeight: '60px',
      width: '100%',
      margin: "5px 0",
      textAlign: 'left',
      boxShadow: null,
      borderRadius: '0px',
      cursor: cursor === false && 'not-allowed'
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: '60px',
      padding: '0 6px',
      textAlign: 'left',
      fontSize: '13px',
      cursor: cursor === false && 'not-allowed'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000 !important',
      cursor: cursor === false && 'not-allowed'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      textAlign: 'left',
      cursor: cursor === false && 'not-allowed'
    }),
    indicatorSeparator: state => ({
      display: 'none',
      borderLeft: '1px solid',
      cursor: cursor === false && 'not-allowed'
    }),
    // indicatorsContainer: (provided, state) => ({
    //     ...provided,
    //     height: '44px',
    // }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: "#E1F5ED",
      cursor: cursor === false && 'not-allowed'
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#000000",
      cursor: cursor === false && 'not-allowed'
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      display: isDisabled ? 'none' : 'flex',
      color: "#01774A",
      ':hover': {
        backgroundColor: "#01774A",
        color: '#fff',
      },
      transition: 'all 0.2s ease',
      cursor: cursor === false && 'not-allowed'
    }),
  };

  return (
    <div className={inputWrapperStyle} style={{ display: "flex", flexDirection: "column", pointerEvents: isDisabled && 'none', width: componentWidth }}>
      {!!labelTxt && <label className='inputLable' htmlFor={inputId}>{labelTxt}{isRequired && <small style={{ color: "#BC0000", fontWeight: "bold", fontSize: "16px" }}>*</small>}</label>}
      {isCreatable ? (
        <CreatableSelect
          noOptionsMessage={({ inputValue }) => !inputValue && labelTxt === "Skills" ? "Please Select Industry" : "No results found"}
          menuPlacement={menuPlacement}
          placeholder={placeholderTxt}
          value={selectedValue}
          onChange={handleChange}
          isMulti={isMultiValue}
          styles={labelTxt === "Skills" || "Keywords" ? skillsCustomStyles : customStyles(isDisabled)}
          options={selectOptions}
          maxMenuHeight={200}
          id={selectID}
        />
      ) : (
        <Select
          menuPlacement={menuPlacement}
          noOptionsMessage={({ inputValue }) => 
            !selectOptions || selectOptions.length === 0 ? 
            "Please Select Industry" : 
            (!inputValue ? "No results found" : "No results found")
          }
          placeholder={placeholderTxt}
          value={selectedValue}
          onChange={handleChange}
          isMulti={isMultiValue}
          styles={customStyles(isDisabled)}
          options={selectOptions}
          maxMenuHeight={200}
          id={selectID}
        />
      )}
      {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
    </div>
  );
};

export default SelectField;