import React, { useEffect, useState } from "react";
import "./EditJob.css";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Url, selectedWebProfile } from "../../EnvoirnmentSetup/APIs";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import moment from "moment";
import editBtn from "../../assets/edit.svg.svg";
import deleteBtn from "../../assets/delete.png";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { BsArrowDownUp, BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from "../../Context/Context";
import eyeIcon from '../../assets/eyeIconblue.svg'
import deleteIcon from '../../assets/deleteIcon.svg'
import closeIcon from '../../assets/closeIcon.svg'
import nodata from '../../assets/nodata.png';
import breadCrumbArrow from '../../assets/backArrowGray.svg'
import shareIconBlue from '../../assets/shareIconBlue.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qSubmit from '../../assets/QSubmit.svg'

const EditJob = () => {
  const navigate = useNavigate();
  const [jobList, setJobList] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [deletemodalIsOpen, setDeletemodalIsOpen] = useState(false);
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  let storedFromDate = sessionStorage.getItem("fromDate");
  storedFromDate = JSON.parse(storedFromDate)
  const [fromDate, setFromDate] = useState(storedFromDate);
  let storedToDate = sessionStorage.getItem("toDate");
  storedToDate = JSON.parse(storedToDate)
  const [toDate, setToDate] = useState(storedToDate);  const [initialPageIndex, setInitialPageIndex] = useState(0);
  const [jobListByDatePosted, setJobListByDatePosted] = useState([]);
  const {subMenu, setSubMenu, subMenuColor, setSubMenuColor} = useSelectedSubMenuContext();
  const {subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs} = useSelectedSubMenuJobContext();
  let seachFieldText = sessionStorage.getItem("searchTextString");
  seachFieldText = JSON.parse(seachFieldText)
  const [searchText, setsearchText] = useState(seachFieldText ? seachFieldText : "");
  const [abortController, setAbortController] = useState(null);
  const [noDataMsg,setNoDataMsg] = useState("Currently no data available.")
  

  const [callNumber, setcallNumber] = useState(0);


  const [sortingOptions, setSortingOptions] = useState([
    {
      displayName: "Sort By Posted Date",
      valueTobeSent: "postedDate"
    },
    {
      displayName: "Sort By Active Till Date",
      valueTobeSent: "tillDate"
    },
    // {
    //   displayName: "Sort By Experience",
    //   valueTobeSent: "experience"
    // }
  ])
  let sortingOptionData = sessionStorage.getItem("filterOption");
  sortingOptionData = JSON.parse(sortingOptionData)
  console.log({sortingOptionData})
  const [selectedSortingOption, setSelectedSortingOption] = useState({
    displayName: sortingOptionData?.displayName  ? sortingOptionData?.displayName : "Sort By Posted Date",
    valueTobeSent: sortingOptionData?.valueTobeSent  ? sortingOptionData?.valueTobeSent : "postedDate"
  })
  //handle pagination states
  const pagenumberData=sessionStorage.getItem("storedPageNumber");
  const [selectedPageNumber, setSelectedPageNumber] = useState({
    actualNumber:pagenumberData ? +pagenumberData : 1,
    gotoNumber: pagenumberData ? +pagenumberData : 1,
  });
  const [totalpages, setTotalpages] = useState(0);
  const pageEntriesLength = sessionStorage.getItem("pageEntriesLength");
  const [pageEntries, setPageEntries] = useState(pageEntriesLength ? +pageEntriesLength : 50);
  let DateSubmitStatus = sessionStorage.getItem("DateSubmitStatus");
  DateSubmitStatus = JSON.parse(DateSubmitStatus)
  const [isfilteredByDaterange, setIsfilteredByDaterange] = useState({
    status: DateSubmitStatus ? DateSubmitStatus : false,
  });
  
  console.log({ selectedPageNumber });

  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "25px",
      minWidth: "35%"
    },
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Job Title",
        accessor: "designation[0].designationName",
        width: "13%"
      },
      {
        Header: "Company Name", accessor: "company.companyName", width: "12%" 
      },
      { Header: "Work Location", accessor: "stateString", width: "12%" },
      {
        Header: "Salary (LPA)",
        accessor: "salaryString",
        width: "12%"
      },
      // {
      //   Header: "Experience",
      //   accessor: "experience.experience",
      //   width: "8%"
      // },
    ],
    []
  );

  const {
    state: { pageIndex, pageSize },
    setPageSize,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page, // Add page variable
    nextPage, // Add nextPage function
    previousPage, // Add previousPage function
    canNextPage, // Add canNextPage variable
    canPreviousPage, // Add canPreviousPage variable
    gotoPage, // Function to go to a specific page
    pageCount, // Total number of pages
  } = useTable(
    {
      columns,
      data: jobList,
      initialState: {
        pageIndex: initialPageIndex,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination // Add usePagination hook
  );
  const { globalFilter } = state;

  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  useEffect(()=>{
    setSelectedCard(2)
    const pageEntriesLength2 = sessionStorage.getItem("pageEntriesLength");
    setPageSize(pageEntriesLength2 ? +pageEntriesLength2 : 50)

    // console.log({ DateSubmitStatus })

    // if (!!DateSubmitStatus) {
    //   setIsfilteredByDaterange({...isfilteredByDaterange, status:!!DateSubmitStatus})
    // }
    setSubMenu(false)
    setSubMenuColor(0)
    setSubMenuJob(true)
    setSubMenuColorMyJobs(2)
  },[])
  
  useEffect(() => {
    // Cleanup: If the component unmounts, abort any ongoing request
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  useEffect(() => {
  
    console.log({ searchText })
    if (searchText?.length > 2) {
      searchCreatedJobs(searchText)
    } else if(searchText?.length == 0) {
      console.log("val////////////////",selectedPageNumber?.actualNumber,
        pageEntries,
        isfilteredByDaterange,
        selectedSortingOption,
        searchText,)
        if (abortController) {
          // If there is an ongoing request, abort it
          abortController.abort();
          getAllCreatedJobs();
        }else{
          getAllCreatedJobs();
        }
    }
    sessionStorage.setItem("searchTextString", JSON.stringify(searchText));
  }, [
    selectedPageNumber?.actualNumber,
    pageEntries,
    isfilteredByDaterange,
    selectedSortingOption,
    searchText,
  ]);


  useEffect(() => {
    let newPage = parseInt(state.pageIndex) + 1;
    console.log({ newPage });
    sessionStorage.setItem("TablePageNumber", JSON.stringify(newPage));
    // setInitialPageIndex(newPage)

  }, []);
  


  function openDeleteModal() {
    setDeletemodalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeletemodalIsOpen(false);
  }
  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  function openSuccessModal() {
    setSuccessModalIsOpen(true);
  }

  function closeSuccessModal() {
    setSuccessModalIsOpen(false);
  }




  const getAllCreatedJobs = async () => {
    // setIsLoading(true)
    console.log("calllnumberrrrr************",callNumber)
    setcallNumber(callNumber+1)

    console.log("running get API*****************************")
    const controller = new AbortController();
    setAbortController(controller);
    console.log({ isfilteredByDaterange });
    try {

      setFetching(true);
      let token = sessionStorage.getItem("token");

      let APIURL =
        isfilteredByDaterange?.status == true
          ? Url.getposterJobswithDatefilter
            .replace("{pageSize}", pageEntries)
            .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1)
            .replace("{from}", moment(fromDate).format("yyyy-MM-DD"))
            .replace("{to}", moment(toDate).format("yyyy-MM-DD")).replace("{field}", selectedSortingOption?.valueTobeSent)
          : Url.getposterJobswithoutDatefilter
            .replace("{pageSize}", pageEntries)
            .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1).replace("{field}", selectedSortingOption?.valueTobeSent);

      console.log({ APIURL });
      fetch(APIURL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        signal: controller.signal,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res?.SUCCESS) {
            console.log("getDetails********", res?.DATA );
            setFetching(false);
            setTotalpages(res?.DATA?.totalPages);
            if(res?.DATA?.jobs?.length==0){
              setNoDataMsg("Currently no data available.")
             }
            let receivedData = [...res?.DATA?.jobs]

            let changedData = receivedData?.map((i) => {
              // console.log("rrrrrrr", i?.applicantCount);

              let stateString = "";
              i?.state?.map((j, index) => {
                if (index < i.state?.length - 1) {
                  stateString = stateString + j.stateName + ", ";
                } else {
                  stateString = stateString + j.stateName;
                }
              });
              let designationString = "";
              i?.designation?.map((j, index) => {
                if (index < i.designation?.length - 1) {
                  designationString =
                    designationString + j.designationName + ", ";
                } else {
                  designationString = designationString + j.designationName;
                }
              });
              let salaryString = !!i?.salary? i?.countryCode?.currency + i?.salary + " LPA" : "Not Disclosed";
              let combineMobileNumber =
                i?.jobPoster?.countryCode?.countryCode +
                i?.jobPoster?.mobileNumber;
              let salaryWithCurrency = !!i.salary
                ? i?.countryCode?.currencyPosition
                  ? i?.countryCode?.currency + " "+i.salary
                  : i.salary + i?.countryCode?.currency
                : i?.countryCode?.currency + " "+ "Not Disclosed";
              let totalViewed = !!i?.jobViewAuditDto  ? i?.applicantCount?.viewd + i?.jobViewAuditDto?.userViews  : i?.applicantCount?.viewd;
              return {
                ...i,
                stateString,
                designationString,
                combineMobileNumber,
                salaryWithCurrency,
                totalViewed,
                salaryString
              };
            });
            console.log(
              "Get Created Jobs...........", callNumber,
              APIURL,
              JSON.stringify(changedData),
              res
            );

            setJobList(changedData);

          
          }
        });
    } catch (error) {
      console.log("Get Created Jobs.error..........", error);
    }finally{
      setAbortController(null);
    }
  };

  const searchCreatedJobs = async (text) => {
    console.log("running search API*****************************")

    if (text?.length >= 2) {
      console.log({ isfilteredByDaterange });
      try {
        setFetching(true);
        let token = sessionStorage.getItem("token");
        let APIURL =
          isfilteredByDaterange?.status == true
            ? Url.searchposterJobswithDatefilter
              .replace("{pageSize}", pageEntries)
              .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1)
              .replace("{from}", moment(fromDate).format("yyyy-MM-DD"))
              .replace("{to}", moment(toDate).format("yyyy-MM-DD"))
              .replace("{keyword}", text).replace("{field}", selectedSortingOption?.valueTobeSent)
            : Url.searchposterJobswithoutDatefilter
              .replace("{pageSize}", pageEntries)
              .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1)
              .replace("{keyword}", text).replace("{field}", selectedSortingOption?.valueTobeSent);

        console.log({ APIURL });
        fetch(APIURL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            console.log("Search/*/*/*/*/*/*/*/",res);
            if (res?.SUCCESS) {
              setFetching(false);
              setTotalpages(res?.DATA?.totalPages);
              if(res?.DATA?.jobs?.length==0){
                setNoDataMsg("No records found!")
              }
              let changedData = res?.DATA?.jobs?.map((i) => {
                let stateString = "";
              let salaryString = !!i?.salary? i?.countryCode?.currency + i?.salary + " LPA" : "Not Disclosed";
                i?.state?.map((j, index) => {
                  if (index < i.state?.length - 1) {
                    stateString = stateString + j.stateName + ", ";
                  } else {
                    stateString = stateString + j.stateName;
                  }
                });
                let designationString = "";
                i?.designation?.map((j, index) => {
                  if (index < i.designation?.length - 1) {
                    designationString =
                      designationString + j.designationName + ", ";
                  } else {
                    designationString = designationString + j.designationName;
                  }
                });

                let combineMobileNumber =
                i?.jobPoster?.countryCode?.countryCode +
                i?.jobPoster?.mobileNumber;
              let salaryWithCurrency = !!i.salary
                ? i?.countryCode?.currencyPosition
                  ? i?.countryCode?.currency + " "+i.salary
                  : i.salary + i?.countryCode?.currency
                : i?.countryCode?.currency + " "+ "Not Disclosed";
                let totalViewed = !!i?.jobViewAuditDto  ? i?.applicantCount?.viewd + i?.jobViewAuditDto?.userViews  : i?.applicantCount?.viewd;

                return {
                  ...i,
                  stateString,
                  designationString,
                  combineMobileNumber,
                  salaryWithCurrency,
                  totalViewed,
                  salaryString
                };
              });
              console.log(
                "Get Created Jobs...........",
                APIURL,
                JSON.stringify(changedData),
                res
              );
          
              setJobList([...changedData]);
            }
          });
      } catch (error) {
        console.log("Get Created Jobs.error..........", error);
      } finally {
        //   setIsLoading(false)
      }
    } else if (text?.length == 0) {
      getAllCreatedJobs();
    } else {
    }
    // setIsLoading(true)
  };

  const DeleteJob = (jobId) => {
    setIdToDelete(jobId);
    openDeleteModal();
  };

  const handleDeleteUser = async () => {
    closeDeleteModal();
    let token = sessionStorage.getItem("token");
    fetch(Url?.deleteJob.replace("{jobId}", idToDelete), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res?.SUCCESS) {
          getAllCreatedJobs();
          setsuccessMsg(`This job is deleted successfully!`);
          openSuccessModal();
        } else {
          let originalString = res?.message;
          if(originalString.includes(':')){
            let resultString = originalString
            .split(":")
            .slice(1)
            .join(":")
            .trim();
          setError(resultString);
          }else{
            setError(originalString);
          }
          openFailModal();
        }
      });
  };

  // const sortBySelectedDateRange = () =>{

  //   const datesBetween = jobListByDatePosted.filter(date => {
  //     let referenceDate = moment(date.postedDateTime).format("yyyy-MM-DD")
  //     let newfromDate =  moment(fromDate).format("yyyy-MM-DD")
  //     let newtoDate = moment(toDate).format("yyyy-MM-DD")
  //     console.log("innnnnn" ,moment(referenceDate).isBetween(newfromDate,newtoDate, null, '[]'),referenceDate, newfromDate, newtoDate)

  //    if(moment(referenceDate).isBetween(newfromDate,newtoDate, null, '[]')){
  //     return date
  //    }
  //   });

  //   console.log({datesBetween})
  //   setJobList(datesBetween)
  // }

  const clearDateFilter = () => {
    setSelectedPageNumber({
      actualNumber: 1,
      gotoNumber: 1,
    });
    setFromDate(null)
    setToDate(null)
    setIsfilteredByDaterange({ ...isfilteredByDaterange, status: false });
  };



  const copyPath = async (jobId) => {
    let jobIdString1 = jobId
    let btoaId1 = btoa(jobIdString1)
     const textarea = document.createElement('textarea');
     textarea.value =  selectedWebProfile.replace("{jobId1}",btoaId1).replace("{jobId2}",btoaId1);
     textarea.style.top = 0;
     textarea.style.left = 0;
     textarea.style.width = '1px';
     textarea.style.height = '1px';
     textarea.style.opacity = 0;
     textarea.setAttribute('readonly', '');
 
     document.body.appendChild(textarea);
     textarea.select();
 
     try {

       document.execCommand('copy');
       console.log('Text copied to clipboard');
       toast.success("Link copied to clipboard");
     } catch (err) {
       console.error('Unable to copy text to clipboard', err);
       toast.error("Error copying link to clipboard")
     }
 
     document.body.removeChild(textarea);

  }

  const navigateCompanyPage = (cell) => {
    window.open(cell?.row?.original?.company?.companyProfile,'_blank')
  }

  return (
    <div>
      {/* GO Back functionality */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: ' 5px 13px 0',marginTop:0}}>
        <p className="breadCrumbDiv1p" style={{marginBottom:"5px"}}>My Postings</p>
        <ul className="breadCrumb">
        <li className="breadcrumbDiv2"><img className="breadcrumbDiv2Img" src={breadCrumbArrow} alt="" />
        <span onClick={()=>navigate(-1)} style={{color:"#007749"}}>My Postings</span>
        </li>
        </ul>
        {/* <p
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to homepage
        </p> */}
      </div>
    <div className="myJobsUpperDiv">
        <div className="EntryCount">
          <p>Show</p>
          <div></div>
          <select
            className="selectEntry"
            value={pageEntries}
            onChange={(e) => {
              setPageEntries(Number(e.target.value));
              setPageSize(Number(e.target.value));
              setSelectedPageNumber({
                actualNumber: 1,
                gotoNumber: 1,
              });
            sessionStorage.setItem("pageEntriesLength", JSON.stringify(e.target.value));

            }}
          >
            {[50, 100]?.map((pageSizeOption) => (
              <option key={pageSizeOption} value={pageSizeOption}>
                1-{pageSizeOption}
              </option>
            ))}
          </select>
        </div>

        <div className="fromDate datePickerClass">
          <p>From Date</p>
          <ComponentAndScreenConstant.DatePickerComponent
            placeholderText="Select date"
            label={""}
            // minDate={new Date()}
            maxDate={new Date()}
            isRequired={true}
            InputSty={{
              backgroundColor: "#FFF",
              textAlignVertical: "top",
              // height: 100,
              color: "#222B45",
              fontSize: 14,
            }}
            selectedDate={fromDate ? new Date(fromDate) : null}
            onChange={(e) => {
              console.log(e);
              setFromDate(e);
              setToDate(null)

            }}
            isReadOnly={isfilteredByDaterange?.status}
            minYearsRequired={6}
          />
        </div>
        <div className="ToDate datePickerClass">
          <p>To Date</p>
          <ComponentAndScreenConstant.DatePickerComponent
            placeholderText="Select date"
            label={""}
            // minDate={new Date()}
            maxDate={new Date()}
            minDate={new Date(fromDate)}
            isRequired={true}
            InputSty={{
              backgroundColor: "#FFF",
              textAlignVertical: "top",
              // height: 100,
              color: "#222B45",
              fontSize: 14,
            }}
            selectedDate={toDate ? new Date(toDate) : null}
            onChange={(e) => {
              console.log(e);
              setToDate(e);
            }}
            isReadOnly={isfilteredByDaterange?.status}
            minYearsRequired={6}
          />
        </div>
        {!isfilteredByDaterange?.status ? (
          <button
            className="dateSubmit"
            onClick={() => {
              // sortBySelectedDateRange()
              if(!!fromDate && !!toDate){
              sessionStorage.setItem("fromDate", JSON.stringify(fromDate));
              sessionStorage.setItem("toDate", JSON.stringify(toDate));
              sessionStorage.setItem("DateSubmitStatus", JSON.stringify(true));
              setIsfilteredByDaterange({
                ...isfilteredByDaterange,
                status: true,
              });
              setSelectedPageNumber({
                actualNumber: 1,
                gotoNumber: 1,
              })}else{
                setError("Please select the date range.");
                openFailModal();
              }
            }}
          >
            <img src={qSubmit} alt="something went wrong"/>
          </button>
        ) : (
          <button
            className="dateSubmit"
            onClick={() => {
              clearDateFilter();
              sessionStorage.removeItem("toDate");
              sessionStorage.removeItem("fromDate");
              sessionStorage.setItem("DateSubmitStatus", JSON.stringify(false));

            }}
            style={{color:"#007749", border:"1.5px solid #007749", backgroundColor:"#FFFFFF"}}
          >
            Clear
          </button>
        )}

        <input
          type="text"
          placeholder="Search..."
          value={searchText || ""}
          onChange={(e) => {
            setsearchText(e.target.value);
          }}
          className="EditSearchInput"
        />
        <div className="sortFilter">
          <select
            value={selectedSortingOption?.displayName}
            onChange={(e) => {
              console.log("jjjjj", e.target.selectedIndex)
              setSelectedSortingOption(sortingOptions[e.target.selectedIndex])
              let filtringoption = sortingOptions[e.target.selectedIndex]
              sessionStorage.setItem("filterOption",JSON.stringify(filtringoption));
            }}
            className="editSortfilter"
          >
            {sortingOptions.map((i) => { return i.displayName }).map((entries, index) => (
              <option key={entries} value={entries}>
                {entries}
              </option>
            ))}
          </select>
        </div>
        {/* <button
          onClick={() => {
            navigate("/createjobs");
          }}
          className="EditCreateJob"
        >
          Create Job
        </button> */}
      </div>
      <div>
        <div className="EditJobTableMainDiv">

           {page.length == 0 ? <div className="noNotificationMsg">
            <img
              src={nodata}
              className="nodataImage"
            />
            <p>{noDataMsg}</p>
          </div> :
            <>
          <div className="EditJobOuterDiv">
          <div className="EditJobTableSubDiv">
          <table
            {...getTableProps()}
            className="tableStyleMyJobs"
          >
            <thead style={{position: "sticky", top:"0"}}>
              {headerGroups?.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  style={{ backgroundColor: '#007749', color: '#fff' }}
                >
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ cursor: "pointer", borderTopLeftRadius:"10px" ,borderRight:"0.5px solid gray",width:"2%"}}
                    
                  >
                    <span>#</span>
                  </th>
                  {headerGroup?.headers?.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        width: column.width,
                        borderRight: "0.5px solid gray"
                      }}
                      key={column.id}
                    >
                      {column.render("Header")}
                      {/* <span style={{ padding: '2%', textAlign: 'center', height: "auto", alignSelf: "center" }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <BsArrowDown size={18} />
                          : <BsArrowUp size={18} />
                        : <BsArrowDownUp ssize={18} />
                      }
                    </span> */}
                    </th>
                  ))}
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ cursor: "pointer", borderRight:" 0.5px solid gray", width:"10%"}}
                  >
                    <span>Posted Date</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ cursor: "pointer", borderRight:"0.5px solid gray", width:"12%" }}
                  >
                    <span>Active Till</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ cursor: "pointer", borderRight: "0.5px solid gray" }}
                  >
                    Applied
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ cursor: "pointer", borderRight: "0.5px solid gray" }}
                  >
                    Viewed
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ cursor: "pointer", borderRight: "0.5px solid gray" }}
                  >
                    Sent To
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ cursor: "pointer", borderRight: "0.5px solid gray" }}
                  >
                    Hired
                  </th>

                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ cursor: "pointer", borderTopRightRadius:"10px" }}
                  >
                    Action
                  </th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{borderBottom:"0.5px solid gray"}}>
              {page?.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{ borderBottom: "1px solid #ddd",backgroundColor:row?.index%2==0? "#C1E7D8":"#ffff" }}
                    key={row.original.jobId}
                  >
                    <td style={{borderRight: "0.5px solid gray",height:"85px"}} >
                      <p style={{ textAlign: "center" }}>{index + 1}</p>
                    </td>
                    {row?.cells?.map((cell) => (
                      <td {...cell.getCellProps()} style={{ padding: "10px", borderRight: "0.5px solid gray" ,color: cell.column.id == 'designation[0].designationName'? "blue" :cell.column.id==='company.companyName' ?'blue':"#000"}}>
                        {cell.column.id === 'designation[0].designationName' ? (

                          <div style={{cursor:"pointer",overflow:"hidden",textOverflow:"ellipsis"}} onClick={() => {
                            let newPage = parseInt(state.pageIndex);
                            console.log("setPageNumber", newPage);
                            sessionStorage.setItem("TablePageNumber", JSON.stringify(newPage));
                            navigate("/adminDashboard/editjobs", {
                              state: { flow: "edit", jobId: row.original.jobId },
                            });
                          }}>
                            {cell.render('Cell')}
                          </div>
                        ) : cell.column.id == 'company.companyName'? 
                          <div style={{cursor:"pointer"}} onClick={()=>navigateCompanyPage(cell)}>
                            {cell.render('Cell')}
                          </div>
                        : (
                          <label className={cell.column.id == 'stateString' ? 'ThreeLine':''}>
                          {cell.render('Cell')}
                          </label>
                        )}
                      </td>
                    ))}
                    <td style={{borderRight: "0.5px solid gray"}}>
                      <p style={{ textAlign: "center" }}>{moment(row?.original?.postedDate).format("DD-MMM-YYYY")}</p>
                    </td>
                    <td style={{borderRight: "0.5px solid gray"}}>
                      <p style={{ textAlign: "center" }}>{moment(row?.original?.tillDate).format("DD-MMM-YYYY")}</p>
                    </td>
                    <td style={{borderRight: "0.5px solid gray",padding:"10px 0px"}}>
                      <div className="CounterButtonsWrapper" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                      <button className="CounterButtons" style={{ backgroundColor: row?.index%2==0? "#ffff":"#E8F2FF", color:"#007749",fontSize:'16px'}} onClick={() => {
                          if (row.original.applicantCount.intrested != 0) {
                            navigate("/adminDashboard/joblist/appliedby", {
                              state: { flow: "Applied", jobId: row.original.jobId, jobDetails: row.original, showJobDetailBreadCrumb:false },
                            })
                          }
                        }} key={row.original.jobId.toString()}>{row.original.applicantCount.intrested}</button>
                      </div>
                    </td>
                    <td style={{borderRight: "0.5px solid gray",padding:"10px 0px"}}>
                      <div className="CounterButtonsWrapper" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                      <button className="CounterButtons" style={{ backgroundColor: row?.index%2==0? "#ffff":"#E8F2FF", color:"#007749",fontSize:'16px'}} onClick={() => {
                          if (row?.original?.applicantCount?.viewd != 0) {
                            navigate("/adminDashboard/joblist/viewdby", {
                              state: { flow: "Viewed", jobId: row.original.jobId, jobDetails: row.original, showJobDetailBreadCrumb:false },
                            })
                          }
                          else if(!!row?.original?.jobViewAuditDto?.userViews){
                            if(row?.original?.jobViewAuditDto?.userViews == 1){
                              setError(`The only user is currently unregistered.`);
                              openFailModal();
                            }
                            else{
                            setError(`All the ${row?.original?.jobViewAuditDto?.userViews} users are unregistered visitors.`);
                            openFailModal();
                            }
                          }
                        }}>{row?.original?.totalViewed}</button>
                      </div>
                    </td>
                    <td style={{borderRight: "0.5px solid gray",padding:"10px 0px"}}>
                      <div className="CounterButtonsWrapper" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                      <button className="CounterButtons" style={{ backgroundColor: row?.index%2==0? "#ffff":"#E8F2FF", color:"#007749",fontSize:'16px'}} onClick={() => {
                          if (row.original.applicantCount.sendto != 0) {
                            navigate("/adminDashboard/joblist/sentto", {
                              state: { flow: "Sent to", jobId: row.original.jobId, jobDetails: row.original, showJobDetailBreadCrumb:false },
                            })
                          }
                        }}>{row.original.applicantCount.sendto}</button>
                      </div>
                    </td>
                    <td style={{borderRight: "0.5px solid gray",padding:"10px 0px"}}>
                      <div className="CounterButtonsWrapper" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                      <button className="CounterButtons" style={{ backgroundColor: row?.index%2==0? "#ffff":"#E8F2FF", color:"orange",fontSize:'16px'}} onClick={()=>{
                        if (row.original.applicantCount.selected != 0) {
                          navigate("/adminDashboard/job/hired", {
                            state: { flow: "Applied", jobId: row.original.jobId, jobDetails: row.original, showJobDetailBreadCrumb:false },
                          })
                        }
                      }}>{row?.original?.applicantCount?.selected}</button>
                      </div>
                    </td>
                    <td>
                    <div style={{display:"flex", alignItems:"center"}}>
                    <img
                        src={eyeIcon}
                        className="eyeIconblue"
                        style={{padding:"5px 5px"}}
                        onClick={() => {
                          let newPage = parseInt(state.pageIndex);
                          console.log("setPageNumber", newPage);
                          sessionStorage.setItem("TablePageNumber", JSON.stringify(newPage));
                          navigate("/adminDashboard/jobDetails", {
                            state: { flow: "Viewed", jobId: row.original.jobId },
                          });
                        }}
                      />
                      
                      <img
                        src={editBtn}
                        className="editBtn"
                        style={{padding:"5px 5px"}}
                        onClick={() => {
                          let newPage = parseInt(state.pageIndex);
                          console.log("setPageNumber", newPage);
                          sessionStorage.setItem("TablePageNumber", JSON.stringify(newPage));
                          navigate("/adminDashboard/editjobs", {
                            state: { flow: "edit", jobId: row.original.jobId },
                          });
                        }}
                      />
                      <img
                        src={shareIconBlue}
                        className="editBtn"
                        style={{padding:"5px 5px"}}
                        onClick={() => {
                          copyPath(row.original.jobId)
                        }}
                      />
                      <img
                        src={deleteBtn}
                        className="editBtn"
                        style={{padding:"5px 5px"}}
                        onClick={() => {
                          let newPage = parseInt(state.pageIndex);
                          console.log("setPageNumber", newPage);
                          sessionStorage.setItem("TablePageNumber", JSON.stringify(newPage));
                          DeleteJob(row.original.jobId);
                        }}
                      />
                    </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }} className="editJobpagination">
                <ComponentAndScreenConstant.TablePagination
                  setPageNumber={setSelectedPageNumber}
                  pagenumber={selectedPageNumber}
                  totalpages={totalpages}
                  isSavedPageNumberNeeded={true}
                />
              </div></>
          }
        </div>
        {/* <div className="managePrevNextPageWrapper">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="previousNextButton"
          >
            {"<<"}
          </button>{" "}
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="previousNextButton"
          >
            {"<"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {parseInt(state.pageIndex)  + 1} of {pageCount}
            </strong>{" "}
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="previousNextButton"
          >
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="previousNextButton"
          >
            {">>"}
          </button>{" "}
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={state.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{
                width: "50px",
                border: "1px solid var(----Color3)",
                borderRadius: "5px",
              }}
            />
          </span>{" "}
        </div> */}
      
      </div>

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
      <Modal
        isOpen={deletemodalIsOpen}
        onRequestClose={closeDeleteModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="deleteModalWrapper" >
          <div className="delAccModalCrossDiv">
          <img src={closeIcon} alt="Close Icon" style={{cursor:"pointer"}} onClick={closeDeleteModal} />          </div>
        </div>
        <div className="delAccModalErrMsg" style={{margin:0,padding:0}}>
          <img src={deleteIcon} alt="Delete Icon"/>
          <p>Would you like to delete this job post?</p>
          <div>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #007749",
                background: 'none',
                color: '#007749'
              }}
              onClick={closeDeleteModal}
            >
              Cancel
            </button>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "none",
                color: "#FFF",
                backgroundColor: "#BC0000",
              }}
              onClick={() => {
                handleDeleteUser();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={closeFailModal}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeFailModal}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={closeSuccessModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={closeSuccessModal} className="delAccModalCrossPara">
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{successMsg}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={closeSuccessModal}>OK</button>
        </div>
      </Modal>
      <ToastContainer autoClose={2000} />
    </div >
  );
};

export default EditJob;
