import React, { useEffect, useState } from "react";
import "./ApplicantRepository.css";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Url } from "../../EnvoirnmentSetup/APIs";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import moment from "moment";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { BsArrowDownUp, BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useSelectedCardContext } from "../../Context/Context";
import searchIcon from '../../assets/searchLogo.svg'
import whatsappIcon from '../../assets/whatsapp.svg'
import nodata from '../../assets/nodata.png';
import breadCrumbArrow from '../../assets/backArrowGray.svg'
import editBtn from "../../assets/edit.svg.svg";
import deleteBtn from "../../assets/delete.png";
import deleteIcon2 from '../../assets/deleteIcon.svg'
import closeIcon from '../../assets/closeIcon.svg'
import { Calendlyaccount, emailValidationForApplicant, field, onlyAlphabets, onlyLinkedInProfile, onlyLinkedInProfileAndResume } from "../../Validation/Validation";

const ApplicantRepository = () => {
  const navigate = useNavigate();
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  useEffect(() => {
    setSelectedCard(3);
    setPageSize(50)
  }, []);

  const [applicantList, setApplicantList] = useState([]);
  const [noDataMsg, setNoDataMsg] = useState("Search through applicants here!")
  const [fetching, setFetching] = useState(false);
  const [fetchApiAgain, setFetchApiAgain] = useState(false)
  const [idToDelete, setIdToDelete] = useState("");
  const [deletemodalIsOpen, setDeletemodalIsOpen] = useState(false);
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [initialPageIndex, setInitialPageIndex] = useState(0);
  const [applicantListByDatePosted, setApplicantListByDatePosted] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [deleteApplicantModal, setDeleteApplicantModal] = useState(false)
  const [applicantObjForDel, setApplicantObjForDel] = useState({ countryCodeId: "", mobileNumber: "" })
  const [editApplicantModal, setEditApplicantModal] = useState(false)
  const [editObj, setEditObj] = useState({ applicantName: field, emailId: field, mno: field, linkedinProfile: field, yearsOfExperience: field, mobileNumber: "", countryCode: {},calendlyProfile:field })
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])
  const [KeywordsSuggestions, setKeywordsSuggestions] = useState([]);
  const [selectedWorkMode, setSelectedWorkMode] = useState([])
  const [LocationSuggestions, setLocationSuggestions] = useState([]);
  const [rstSelector, setrstSelector] = useState()
  const [Experience, setExperience] = useState([]);
  const [KeywordsEntries, setKeywordsEntries] = useState([]);
  const [LocationEntries, setLocationEntries] = useState([])
  const [selectedExperience, setSelectedExperience] = useState({});
  const [sortingOptions, setSortingOptions] = useState([
    {
      displayName: "Sort By Keywords",
      valueTobeSent: "skill",
    },
    {
      displayName: "Sort By Applicant Name",
      valueTobeSent: "applicant",
    },
    {
      displayName: "Sort By Experience",
      valueTobeSent: "experience",
    },
  ]);
  const [selectedSortingOption, setSelectedSortingOption] = useState({
    displayName: "Sort By Keywords",
    valueTobeSent: "skill",
  },);
  //handle pagination states
  const [selectedPageNumber, setSelectedPageNumber] = useState({
    actualNumber: 1,
    gotoNumber: 1,
  });
  const [totalpages, setTotalpages] = useState(0);
  const [pageEntries, setPageEntries] = useState(50);
  const [isfilteredByDaterange, setIsfilteredByDaterange] = useState({
    status: false,
  });

  console.log({ selectedPageNumber });

  const editApplicantStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: window.screen.width > 1023 ? "60%" : "85%",
      borderRadius: "25px",
      padding: "10px",
      height: window.screen.width > 1023 ? "85vh" : "80vh"
    },
  };

  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };
  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "35%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const columns = React.useMemo(
    () => [
      { Header: "Keywords", accessor: "skillString",width:"12%" },
      {
        Header: "Experience",
        accessor: "experience.experience",
        width: "5%",
      },
      {
        Header: "Work Location",
        accessor: "stateString",
        width: "12%",
      },
    ],
    []
  );

  const {
    state: { pageIndex, pageSize },
    setPageSize,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page, // Add page variable
    nextPage, // Add nextPage function
    previousPage, // Add previousPage function
    canNextPage, // Add canNextPage variable
    canPreviousPage, // Add canPreviousPage variable
    gotoPage, // Function to go to a specific page
    pageCount, // Total number of pages
  } = useTable(
    {
      columns,
      data: applicantList,
      initialState: {
        pageIndex: initialPageIndex,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination // Add usePagination hook
  );
  const { globalFilter } = state;

  useEffect(() => {
    let newPage = parseInt(state.pageIndex) + 1;
    console.log({ newPage });
    sessionStorage.setItem("TablePageNumber", newPage);
    // setInitialPageIndex(newPage)
    GetExperiences()
    getAllKeywords()
    getAllWorkLocations()
  }, []);

  useEffect(() => {
    let pageNumber = sessionStorage.getItem("storedPageNumber");
    if (!!pageNumber) {
      setSelectedPageNumber({
        actualNumber: Number(pageNumber),
        gotoNumber: Number(pageNumber),
      });
    }
    // let seachFieldText = sessionStorage.getItem("searchText");
    // console.log({seachFieldText})
    // if (!!seachFieldText) {
    //   setsearchText(seachFieldText);
    // }
    // let fiterOption =  sessionStorage.getItem("filterOption");
    // if (!!fiterOption) {
    //   fiterOption = JSON.parse(fiterOption)
    //   console.log({fiterOption})
    //   setSelectedSortingOption(fiterOption)
    // }
    // let pageEntriesLength = sessionStorage.getItem("filterOption");
    // if (!!pageEntriesLength) {
    //   setPageEntries(Number(pageEntriesLength));
    //   setPageSize(Number(pageEntriesLength));
    // }
  }, []);

  useEffect(() => {
    console.log({ searchText });
    if (searchText?.length > 2) {
      searchCreatedJobs(searchText);
      sessionStorage.setItem("searchText", searchText);
    } else if (searchText?.length == 0) {
      //getAllApplicantList();
      setApplicantList([])

    }
  }, [
    selectedPageNumber.actualNumber,
    pageEntries,
    isfilteredByDaterange?.status,
    selectedSortingOption?.valueTobeSent,
    searchText, fetchApiAgain
  ]);

  function openDeleteModal() {
    setDeletemodalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeletemodalIsOpen(false);
  }
  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  function openSuccessModal() {
    setSuccessModalIsOpen(true);
  }

  function closeSuccessModal() {
    setSuccessModalIsOpen(false);
  }

  const handleCloseApplicantModal = () => {
    setEditApplicantModal(false)
  }

  const GetExperiences = async () => {
    try {
      // let token = sessionStorage.getItem("token");
      fetch(Url?.GetExperienceList, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log({ res });
          if (res.SUCCESS) {
            // let ExpList =  res?.DATA?.map((k)=>{
            //   return {...k, name:k?.experience}
            // })
            setExperience(res?.DATA);
          }
        });
    } catch (error) {
      console.log("Experience", error);
    }
  };

  const getAllApplicantList = async () => {
    // setIsLoading(true)
    console.log("running get API*****************************");

    try {
      setFetching(true);
      let token = sessionStorage.getItem("token");

      fetch(
        Url?.getApplicantrepositoryList
          .replace("{pageSize}", pageEntries)
          .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1).replace("{field}", selectedSortingOption?.valueTobeSent),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          console.log({ res });
          if (res?.SUCCESS) {
            setFetching(false);
            setTotalpages(res?.DATA?.totalPages);

            let changedData = res?.DATA?.jobs?.map((i) => {
              console.log("rrrrrrr", i?.applicantCount);

              let stateString = "";
              i?.state?.map((j, index) => {
                if (index < i.state?.length - 1) {
                  stateString = stateString + j.stateName + ", ";
                } else {
                  stateString = stateString + j.stateName;
                }
              });
              let skillString = "";
              i?.skill?.map((j, index) => {
                if (index < i.skill?.length - 1) {
                  skillString = skillString + j.name + ", ";
                } else {
                  skillString = skillString + j.name;
                }
              });

              stateString = stateString == "" ? '-' : stateString
              skillString = skillString == "" ? '-' : skillString

              let combineMobileNumber =
                i?.countryCode?.countryCode + i?.mobileNumber;

              let showMobile =
                "+" + i?.countryCode?.countryCode + " " + i?.mobileNumber;
              return {
                ...i,
                stateString,
                combineMobileNumber,
                skillString,
                showMobile
              };
            });
            console.log(
              "Get Created Jobs...........",
              JSON.stringify(changedData)
            );
            // if(changedData.length>0){
            //   setIsJobAvailbale(true)
            // }else{
            //   setIsJobAvailbale(false)
            // }

            // const compareDatesposted = (a, b) =>
            //   moment(a?.postedDateTime).diff(moment(b?.postedDateTime));
            // const copiedJobDataArray1 = [...changedData];
            // const sortedArrayPosted =
            //   copiedJobDataArray1.sort(compareDatesposted);
            // console.log("sortedArrayPosted", JSON.stringify(sortedArrayPosted));
            setApplicantList([...changedData]);
            // setApplicantListByDatePosted(changedData);

            //     const compareDates = (a, b) => moment(a?.tillDate).diff(moment(b?.tillDate));
            //     const copiedJobDataArray2 = [...changedData]

            //     const sortedArray = copiedJobDataArray2.sort(compareDates);
            //     console.log({sortedArray})
            //     setApplicantListByDateFillDate(sortedArray)

            //     const copiedJobDataArray3 = [...changedData]
            //     const sortedJobDataArray = copiedJobDataArray3.sort((a, b) => b.experience.experienceId - a.experience.experienceId);
            //     setApplicantListByExperience(sortedJobDataArray)
          }
        });
    } catch (error) {
      console.log("Get Created Jobs.error..........", error);
    } finally {
      //   setIsLoading(false)
    }
  };

  const getAllWorkLocations = () => {
    try {
      let token = sessionStorage.getItem("token");
      fetch(Url?.getAllLocations, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            console.log("res", res);

            let newData = res?.DATA?.map((i) => {
              return {
                ...i,
                label: i?.stateName,
                value: i?.stateId,
              };
            });
            setLocationSuggestions(newData);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getAllKeywords = (industryId) => {
    try {
      let token = sessionStorage.getItem("token");
      fetch(Url?.getAllSkills, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.SUCCESS) {
            console.log("res", res);

            let newData = res?.DATA?.map((i) => {
              return {
                ...i,
                label: i?.name,
                value: i?.skillId,
              };
            });
            setKeywordsSuggestions(newData);
          }
        });
    } catch (error) {
      console.log("keywords.error..........", error);
    }
  }

  const handleLocationChange = (e) => {
    const selectedLocations = e.map((item) => ({
      value: typeof item.value === 'string' ? undefined : item.value,
      label: item.label
    }));

    const updatedLocations = e.map((item) => ({
      stateId: typeof item.value === 'string' ? undefined : item.value,
      stateName: item.label
    }));

    setSelectedLocations(selectedLocations);
    setLocationEntries(updatedLocations);
  }

  const handleKeywordsChange = async (e) => {
    const selectedKeyWords = e.map((item) => ({
      value: typeof item.value === 'string' ? undefined : item.value,
      label: item.label
    }));

    const updatedKeywords = e.map((item) => ({
      skillId: typeof item.value === 'string' ? undefined : item.value,
      name: item.label
    }));

    setSelectedKeywords(selectedKeyWords);
    setKeywordsEntries(updatedKeywords);
  };

  const searchCreatedJobs = async (text) => {
    console.log(
      "running search API*****************************",
      Url?.getApplicantrepositoryList
        .replace("{pageSize}", pageEntries)
        .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1)
        .replace("{keyword}", text).replace("{field}", selectedSortingOption?.valueTobeSent)
    );

    if (text?.length >= 2) {
      console.log({ isfilteredByDaterange });
      try {
        setFetching(true);
        let token = sessionStorage.getItem("token");

        fetch(
          Url?.searchApplicantfromrepositoryList
            .replace("{pageSize}", pageEntries)
            .replace("{pageNumber}", selectedPageNumber?.actualNumber - 1)
            .replace("{keyword}", text)
            .replace("{field}", selectedSortingOption?.valueTobeSent),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
          .then((response) => response.json())
          .then((res) => {
            console.log({
              res,
            });
            if (res?.SUCCESS) {
              setFetching(false);
              setTotalpages(res?.DATA?.totalPages);
              if (res?.DATA?.jobs?.length == 0) {
                setNoDataMsg("No Applicants Found.")
              }
              let changedData = res?.DATA?.jobs?.map((i) => {
                console.log("rrrrrrr", i?.applicantCount);

                let stateString = "";
                i?.state?.map((j, index) => {
                  if (index < i.state?.length - 1) {
                    stateString = stateString + j.stateName + ", ";
                  } else {
                    stateString = stateString + j.stateName;
                  }
                });
                let skillString = "";
                i?.skill?.map((j, index) => {
                  if (index < i.skill?.length - 1) {
                    skillString = skillString + j.name + ", ";
                  } else {
                    skillString = skillString + j.name;
                  }
                });

                let combineMobileNumber =
                  i?.countryCode?.countryCode + i?.mobileNumber;


                let showMobile =
                  // "+"+i?.countryCode?.countryCode + " " +i?.mobileNumber;
                  `+${i?.countryCode?.countryCode} ${i?.mobileNumber}`
                return {
                  ...i,
                  stateString,
                  combineMobileNumber,
                  skillString,
                  showMobile
                };
              });
              console.log(
                "Get Created Jobs...........",
                JSON.stringify(changedData)
              );
              // if(changedData.length>0){
              //   setIsJobAvailbale(true)
              // }else{
              //   setIsJobAvailbale(false)
              // }

              // const compareDatesposted = (a, b) =>
              //   moment(a?.postedDateTime).diff(moment(b?.postedDateTime));
              // const copiedJobDataArray1 = [...changedData];
              // const sortedArrayPosted =
              //   copiedJobDataArray1.sort(compareDatesposted);
              // console.log("sortedArrayPosted", JSON.stringify(sortedArrayPosted));
              setApplicantList([...changedData]);
              // setApplicantListByDatePosted(changedData);

              //     const compareDates = (a, b) => moment(a?.tillDate).diff(moment(b?.tillDate));
              //     const copiedJobDataArray2 = [...changedData]

              //     const sortedArray = copiedJobDataArray2.sort(compareDates);
              //     console.log({sortedArray})
              //     setApplicantListByDateFillDate(sortedArray)

              //     const copiedJobDataArray3 = [...changedData]
              //     const sortedJobDataArray = copiedJobDataArray3.sort((a, b) => b.experience.experienceId - a.experience.experienceId);
              //     setApplicantListByExperience(sortedJobDataArray)
            }
          });
      } catch (error) {
        console.log("Get Created Jobs.error..........", error);
      } finally {
        //   setIsLoading(false)
      }
    } else if (text?.length == 0) {
      //getAllApplicantList();
      setApplicantList([])

    } else {
    }
    // setIsLoading(true)
  };

  const DeleteJob = (jobId) => {
    setIdToDelete(jobId);
    openDeleteModal();
  };

  const handleDeleteUser = async () => {
    closeDeleteModal();
    let token = sessionStorage.getItem("token");
    fetch(Url?.deleteJob.replace("{jobId}", idToDelete), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res?.SUCCESS) {
          //getAllApplicantList();
          setsuccessMsg(`This job is deleted successfully!`);
          openSuccessModal();
        } else {
          let originalString = res?.message;
          if (originalString.includes(':')) {
            let resultString = originalString
              .split(":")
              .slice(1)
              .join(":")
              .trim();
            setError(resultString);
          } else {
            setError(originalString);
          }
          openFailModal();
        }
      });
  };

  // const sortBySelectedDateRange = () =>{

  //   const datesBetween = applicantListByDatePosted.filter(date => {
  //     let referenceDate = moment(date.postedDateTime).format("yyyy-MM-DD")
  //     let newfromDate =  moment(fromDate).format("yyyy-MM-DD")
  //     let newtoDate = moment(toDate).format("yyyy-MM-DD")
  //     console.log("innnnnn" ,moment(referenceDate).isBetween(newfromDate,newtoDate, null, '[]'),referenceDate, newfromDate, newtoDate)

  //    if(moment(referenceDate).isBetween(newfromDate,newtoDate, null, '[]')){
  //     return date
  //    }
  //   });

  //   console.log({datesBetween})
  //   setApplicantList(datesBetween)
  // }

  const clearDateFilter = () => {
    setSelectedPageNumber({
      actualNumber: 1,
      gotoNumber: 1,
    });
    setFromDate(null);
    setToDate(null);
    setIsfilteredByDaterange({ ...isfilteredByDaterange, status: false });
  };

  const handleDeleteApplicant = (item) => {
    setApplicantObjForDel({ ...applicantObjForDel, countryCodeId: item?.original?.countryCode?.countryCodeId, mobileNumber: item?.original?.mobileNumber })
    setDeleteApplicantModal(true)
  }

  const handleEditApplicant = (item) => {
    setIdToDelete(item?.original?.userId)
    const modifiedKeywords = item?.original?.skill?.map(i => {
      return { ...i, label: i.name, value: i.skillId };
    });
    const modifiedServices1 = item?.original?.skill?.map(i => {
      return { ...i, name: i.name, Id: i.skillId };
    });
    const modifiedWorkLocations = item?.original?.state?.map(i => {
      return { ...i, label: i.stateName, value: i.stateId };
    });
    const modifiedWorkLocations1 = item?.original?.state?.map(i => {
      return { ...i, name: i.stateName, Id: i.stateId };
    });

    setSelectedKeywords(modifiedKeywords?.length>0? modifiedKeywords : []);
    setKeywordsEntries(modifiedServices1?.length>0? modifiedServices1 : [])
    setSelectedLocations(modifiedWorkLocations?.length>0? modifiedWorkLocations : [])
    setLocationEntries(modifiedWorkLocations1?.length>0?modifiedWorkLocations1:[])
    setSelectedExperience(item?.original?.experience);
    setEditObj({
      ...editObj,
      applicantName: { ...field, fieldValue: item?.original?.fullName },
      emailId: { ...field, fieldValue: item?.original?.userName },
      mno: { ...field, fieldValue: item?.original?.showMobile },
      linkedinProfile: { ...field, fieldValue: item?.original?.linkedinProfile },
      yearsOfExperience: { ...field, fieldValue: item?.original?.experience?.experience },
      mobileNumber: item?.original?.mobileNumber,
      countryCode: item?.original?.countryCode,
      calendlyProfile:{...field, fieldValue:item?.original?.calendlyProfile}
    })
    setEditApplicantModal(true)
  }

  const handleConfirmDeleteUser = () => {
    setDeleteApplicantModal(false)
    const obj = {
      "messageAuditId": 0,
      "messageOTP": 1111,
      "mobileNumber": applicantObjForDel?.mobileNumber,
      "countryCode": {
        "countryCodeId": applicantObjForDel?.countryCodeId
      }
    }
    try {
      setFetching(true)
      fetch(Url.DeleteUserAccount, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + sessionStorage.getItem("token")
        },
        body: JSON.stringify(obj)
      })
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
          if (res?.SUCCESS) {
            setFetchApiAgain(new Date())
            setsuccessMsg("Applicant deleted successfully.")
            setSuccessModalIsOpen(true)
            setFetching(false)
          }
          else {
            setError(res?.message)
            setFailmodalIsOpen(true)
            setDeleteApplicantModal(false)
            setFetching(false)
          }
        })
        .catch((e) => {
          console.log(e);
          setFetching(false)
        })
    }
    catch (e) {
      console.log(e);
      setFetching(false)
    }
  }

  const onTextChange = (field, val) => {
    if (field == "Email Id") {
      setEditObj({ ...editObj, emailId: emailValidationForApplicant(field, val) })
    }
    else if (field == "Resume/LinkedIn Profile") {
      setEditObj({ ...editObj, linkedinProfile: onlyLinkedInProfileAndResume(field, val) })
    }
    else if (field == "Applicant Name") {
      setEditObj({ ...editObj, applicantName: onlyAlphabets(field, val) })
    }
    else if(field == 'Calendly Profile'){
      setEditObj({...editObj, calendlyProfile: Calendlyaccount(field, val)})
    }
  }

  const correctedUrl =(givenLink)=>{ 
    givenLink = givenLink?.toLowerCase()
    let data = new Promise((res, rej)=>{
      let updatedNewLink;
      if (givenLink?.startsWith("http://www.") || givenLink?.startsWith("https://www.") || givenLink?.startsWith("http://in.") || givenLink?.startsWith("https://in.")) {
        updatedNewLink = givenLink
      }
      else if (givenLink?.startsWith("www.") || givenLink?.startsWith("in.")) {
        updatedNewLink = `https://${givenLink}`
      }
      else if (givenLink?.startsWith("https://") || givenLink?.startsWith("http://")) {
        let newLink = givenLink
        let colIndex = newLink.indexOf(":") + 3
        let lastIndex = newLink.length
        let strAfterRemove = newLink.slice(colIndex, lastIndex)
        updatedNewLink = `https://www.${strAfterRemove}`
      }
      else {
        updatedNewLink = `https://www.${givenLink}`
      }
      console.log("updatedNewLink",updatedNewLink);
      res(updatedNewLink)
    })
    return data;
    }

  const handleUpdateApplicant = async() => {
    let updatedLinkedInUrl;
    let completeUrl = editObj?.linkedinProfile?.fieldValue?.toLowerCase()
    if (completeUrl?.startsWith("http://www.") || completeUrl?.startsWith("https://www.") || completeUrl?.startsWith("http://in.") || completeUrl?.startsWith("https://in.")) {
      updatedLinkedInUrl = completeUrl
    }
    else if (completeUrl?.startsWith("www.") || completeUrl?.startsWith("in.")) {
      updatedLinkedInUrl = `https://${completeUrl}`
    }
    else if (completeUrl?.startsWith("https://") || completeUrl?.startsWith("http://")) {
      let newLink = completeUrl
      let colIndex = newLink.indexOf(":") + 3
      let lastIndex = newLink.length
      let strAfterRemove = newLink.slice(colIndex, lastIndex)
      updatedLinkedInUrl = `https://www.${strAfterRemove}`
    }
    else {
      updatedLinkedInUrl = `https://www.${completeUrl}`
    }
    const calendlyUpdatedUrl = !!editObj?.calendlyProfile?.fieldValue ?  await correctedUrl(editObj?.calendlyProfile?.fieldValue?.trim()) : ""

    const UserData = {
      userId: idToDelete,
      messageAuditId: 0,
      messageOTP: 1111,
      emailAuditId: 0,
      emailOTP: 1111,
      fullName: editObj?.applicantName?.fieldValue,
      mobileNumber: editObj?.mobileNumber,
      userName: editObj?.emailId?.fieldValue?.toLowerCase(),
      linkedinProfile: updatedLinkedInUrl,
      countryCode: {
        countryCodeId: editObj?.countryCode?.countryCodeId,
      },
      designation: null,
      state: LocationEntries,
      skill: KeywordsEntries,
      experience: selectedExperience,
      calendlyProfile: !!calendlyUpdatedUrl? calendlyUpdatedUrl?.toLowerCase() : null
    };

    if (!(!!editObj?.applicantName?.fieldValue)) {
      setError("Applicant Name is required.")
      setFailmodalIsOpen(true)
    }
    else if (editObj?.applicantName?.isValidField == false) {
      setError("Invalid Applicant Name")
      setFailmodalIsOpen(true)
    }
    else if (!(!!editObj?.emailId?.fieldValue)) {
      setError("Email Id is required")
      setFailmodalIsOpen(true)
    }
    else if (editObj?.emailId?.isValidField == false) {
      setError("Invalid Email Id")
      setFailmodalIsOpen(true)
    }
    else if (!(!!editObj?.linkedinProfile?.fieldValue)) {
      setError("Resume/LinkedIn Profile is required")
      setFailmodalIsOpen(true)
    }
    else if (editObj?.linkedinProfile?.isValidField == false) {
      setError("Invalid Resume/LinkedIn Profile")
      setFailmodalIsOpen(true)
    }
    else if (!(!!selectedExperience?.experienceId)) {
      setError("Please select Years Of Experience")
      setFailmodalIsOpen(true)
    }
    else if (editObj?.calendlyProfile?.isValidField == false) {
      setError("Invalid Calendly Profile URL")
      setFailmodalIsOpen(true)
    }
    else {
      setEditApplicantModal(false)
      setFetching(true)
      fetch(Url.getuser, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + sessionStorage.getItem('token'),
        },
        body: JSON.stringify(UserData)
      })
        .then((resp) => resp.json())
        .then((res) => {
          if (res?.SUCCESS) {
            setFetching(false)
            setFetchApiAgain(new Date())
            setsuccessMsg("Applicant details updated successfully.")
            setSuccessModalIsOpen(true)
          }
          else {
            setEditApplicantModal(true)
            let originalString = res?.message;
            if (originalString.includes(':')) {
              let resultString = originalString
                .split(":")
                .slice(1)
                .join(":")
                .trim();
              setError(resultString);
            } else {
              setError(originalString);
            }
            setFailmodalIsOpen(true)
            setFetching(false)
          }
        })
        .catch((e) => {
          setFetching(false)
          console.log(e);
        })
    }
  }

  return (
    <div>
      {/* GO Back functionality */}
      <div className="appcntRepoFixedContent">
        <div className="applcntRepoOuterDiv" style={{ marginTop: "5px" }}>
          {/* <span className="appRepoName">Search Applicant</span> */}
          <p>Applicants</p>

          {/* <ul className="breadCrumb">
          <li style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}><img style={{width:"8px"}} src={breadCrumbArrow} alt="" />
          <span style={{fontSize:"14px",cursor:"pointer"}} onClick={()=>navigate(-1)}>Search Applicant</span>
          </li>
        </ul> */}
          {/* <span
            onClick={() => {
              navigate(-1);
            }}
            className="applcntRepoP"
          >
            Back to homepage
          </span> */}
        </div>


        <div className="appcntRepoSec2Div2">
          {page.length !== 0 ? <div className="EntryCount entryCountNew">
            <p>Show</p>
            <div></div>
            <select
              className="selectEntry selectEntryNew"
              value={pageEntries}
              onChange={(e) => {
                setPageEntries(Number(e.target.value));
                setPageSize(Number(e.target.value));
                setSelectedPageNumber({
                  actualNumber: 1,
                  gotoNumber: 1,
                });
                sessionStorage.setItem(
                  "pageEntriesLength",
                  JSON.stringify(e.target.value)
                );
              }}
            >
              {[50, 100]?.map((pageSizeOption) => (
                <option key={pageSizeOption} value={pageSizeOption}>
                  1-{pageSizeOption}
                </option>
              ))}
            </select>
          </div> : <div></div>}

          <div className="appcntRepoSearchSortDiv">
            <div className={page.length == 0 ? "appcntRepoSearchDiv" : "appcntRepoSearchDiv2"}>

              <div className="appcntRepoImgDiv">
                <img src={searchIcon} />
              </div>

              <input
                type="text"
                placeholder="Search here..."
                value={searchText || ""}
                onChange={(e) => {
                  setsearchText(e.target.value); setNoDataMsg("Search through applicants here!")
                }}
                className="appcntRepoSearchInput"
              // className="appcntRepoSearch"
              />
            </div>

            {page.length !== 0 ? <div className="sortFilter sortFilterNew">
              <select
                value={selectedSortingOption?.displayName}
                onChange={(e) => {
                  console.log("jjjjj", e.target.selectedIndex);
                  setSelectedSortingOption(
                    sortingOptions[e.target.selectedIndex]
                  );
                  sessionStorage.setItem(
                    "filterOption",
                    JSON.stringify(sortingOptions[e.target.selectedIndex])
                  );
                }}
                className="editSortfilter"
                style={{ outline: "none" }}
              >
                {sortingOptions
                  .map((i) => {
                    return i.displayName;
                  })
                  .map((entries, index) => (
                    <option key={entries} value={entries}>
                      {entries}
                    </option>
                  ))}
              </select>
            </div> : <div></div>}
          </div>
        </div>

      </div>
      <div className="appcntRepoTableMainDiv">
        {
          page.length == 0 ? <div className="noNotificationMsg" style={{ height: "50vh" }}>
            <img
              src={nodata}
              className="nodataImage"
            />
            <p>{noDataMsg}</p>
          </div> :
            <>
              <div className="appcntRepoTableOuterDiv">
                <div className="appcntRepoTableSubDiv">
                  <table {...getTableProps()} className="appcntRepoTableDiv">
                    <thead className="appcntRepoThead">
                      {headerGroups?.map((headerGroup) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          className="appcntRepoTr"
                          style={{ borderRadius: "20px" }}
                        >
                          <th
                            colspan="1"
                            role="columnheader"
                            className="appcntRepoTh"
                            style={{ borderTopLeftRadius: "10px", borderRight: "0.5px solid gray" }}>
                            #<span></span>
                          </th>
                          <th
                            colspan="1"
                            role="columnheader"
                            className="appcntRepoTableTh"
                            style={{ borderRight: "0.5px solid gray", textAlign: "center", width: "18%" }}
                          >
                            Applicant Name (LinkedIn)
                          </th>
                          {headerGroup?.headers?.map((column) => (
                            <th
                              {...column.getHeaderProps()}
                              style={{
                                // border:"1px solid red",
                                padding: "10px",
                                textAlign: "left",
                                width: column.width,
                                borderRight: "0.5px solid gray", textAlign: "center"
                              }}
                              className={column.className}
                            >
                              {column.render("Header")}
                              {/* <span style={{ padding: '2%', textAlign: 'center', height: "auto", alignSelf: "center" }}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <BsArrowDown size={18} />
                        : <BsArrowUp size={18} />
                      : <BsArrowDownUp ssize={18} />
                    }
                  </span> */}
                            </th>
                          ))}
                          <th
                            colspan="1"
                            role="columnheader"
                            className="appcntRepoTableTh"
                            style={{ borderRight: "0.5px solid gray", width: "12%", textAlign: "center" }}
                          >
                            Mobile Number
                          </th>
                          <th
                            colspan="1"
                            role="columnheader"
                            className="appcntRepoTableTh chatWithAppcnt" style={{ textAlign: "center", width: "8%" }}
                          >
                            Chat
                          </th>
                          <th
                            colspan="1"
                            role="columnheader"
                            className="appcntRepoTableTh"
                            style={{ textAlign: "center", borderRight: "1px solid gray",width:"10%"}}>
                            Email Id
                          </th>
                          <th
                            colspan="1"
                            role="columnheader"
                            className="appcntRepoTableTh"
                            style={{ borderTopRightRadius: "10px", textAlign: "center" }}>
                            Action
                          </th>
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="appcntRepoTbody" style={{ borderBottom: "0.5px solid gray" }}>
                      {page?.map((row, index) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="trBorderBottom"
                            key={row.jobId}
                            style={{
                              backgroundColor:
                                row?.index % 2 == 0 ? "#C1E7D8" : "#ffff",
                            }}
                          >
                            <td style={{ borderRight: "0.5px solid gray",height:"100px" }}>
                              <p className="appcntRepoIndex">{index + 1}</p>
                            </td>
                            <td style={{ borderRight: "0.5px solid gray" }}>
                              <div className="ApplicantRepoCounterButtonsWrapper">
                                <p
                                  style={{
                                    // width: "90%",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    margin: "0 5px",
                                    color: row?.original?.linkedinProfile && "blue",
                                    cursor: row?.original?.linkedinProfile && "pointer",
                                  }}
                                  className="maxWidth"
                                  title={row?.original?.fullName}
                                  onClick={() => {
                                    if (row?.original?.linkedinProfile) {
                                      window.open(row?.original?.linkedinProfile);
                                    }
                                  }}
                                >
                                  {row?.original?.fullName}
                                </p>
                              </div>
                            </td>
                            {row?.cells?.map((cell) => {
                             return(
                              cell.column.id == "skillString"?
                              <td
                                style={{ borderRight: "0.5px solid gray" }}
                                {...cell.getCellProps()}
                                className="appcntRepoTdPadding"
                                title={cell.row.original.skillString}
                              >
                                <span className="ThreeLine">{cell.render("Cell")}</span>
                              </td>
                              :cell.column.id == "stateString"?
                              <td
                                style={{ borderRight: "0.5px solid gray" }}
                                {...cell.getCellProps()}
                                className="appcntRepoTdPadding"
                                title={cell.row.original.stateString}
                              >
                                <span className="ThreeLine">{cell.render("Cell")}</span>
                              </td>
                              :
                              <td
                                style={{ borderRight: "0.5px solid gray" }}
                                {...cell.getCellProps()}
                                className="appcntRepoTdPadding"
                              >
                                {cell.render("Cell")}
                              </td>
                             )})}

                            <td style={{ borderRight: "0.5px solid gray" }}>
                              <div className="ApplicantRepoCounterButtonsWrapper">
                                <p
                                  className="ContactlinkWhatsapp"
                                >
                                  {row?.original?.showMobile}
                                </p>
                              </div>
                            </td>
                            <td style={{ borderRight: "0.5px solid gray" }}>
                              <div className="ApplicantRepoCounterButtonsWrapper" style={{ display: "flex", justifyContent: "center" }}>

                                <img
                                  src={whatsappIcon}
                                  className="eyeIconblue"
                                  onClick={() => {
                                    window.open(
                                      "https://wa.me/" +
                                      row?.original?.combineMobileNumber
                                    );
                                  }}
                                />
                              </div>
                            </td>

                            <td style={{ borderRight: "1px solid gray"}}>
                              {/* <div className="ApplicantRepoCounterButtonsWrapper"> */}
                                <p
                                  className="Contactlink maxWidth1"
                                  title={row?.original?.userName}
                                  onClick={() => {
                                    window.open(`mailto:${row?.original?.userName}`);
                                  }}
                                >
                                  {row?.original?.userName}
                                </p>
                              {/* </div> */}
                            </td>
                            <td>
                              <div className="applicantActionCol">
                                <img src={editBtn} onClick={() => handleEditApplicant(row)} />
                                <img src={deleteBtn} onClick={() => handleDeleteApplicant(row)} />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
              <div className="appcntRepoBottomNavs">
                <ComponentAndScreenConstant.TablePagination
                  setPageNumber={setSelectedPageNumber}
                  pagenumber={selectedPageNumber}
                  totalpages={totalpages}
                />
              </div></>


        }

        {/* <div className="ApplicantRepomanagePrevNextPageWrapper">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="ApplicantRepopreviousNextButton"
          >
            {"<<"}
          </button>{" "}
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="ApplicantRepopreviousNextButton"
          >
            {"<"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {parseInt(state.pageIndex)  + 1} of {pageCount}
            </strong>{" "}
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="ApplicantRepopreviousNextButton"
          >
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="ApplicantRepopreviousNextButton"
          >
            {">>"}
          </button>{" "}
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={state.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{
                width: "50px",
                border: "1px solid var(----Color3)",
                borderRadius: "5px",
              }}
            />
          </span>{" "}
        </div> */}


        <div></div>
      </div>
      
      <Modal
        isOpen={deletemodalIsOpen}
        onRequestClose={closeDeleteModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="ApplicantRepodeleteModalWrapper">
          <div className="ApplicantRepodelAccModalCrossDiv">
            <p
              className="ApplicantRepodelAccModalCrossPara"
              onClick={closeDeleteModal}
            >
              ×
            </p>
          </div>
        </div>
        <div className="ApplicantRepodelAccModalErrMsg">
          <p>Would you like to delete this job post?</p>
          <div>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #00ADEE",
              }}
              onClick={closeDeleteModal}
            >
              No
            </button>
            <button
              style={{
                padding: "10px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #00ADEE",
                color: "#FFF",
                backgroundColor: "#00ADEE",
              }}
              onClick={() => {
                handleDeleteUser();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={deleteApplicantModal}
        onRequestClose={() => setDeleteApplicantModal(false)}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="deleteModalWrapper" >
          <div className="delAccModalCrossDiv">
            <img src={closeIcon} alt="Close Icon" style={{ cursor: "pointer" }} onClick={() => setDeleteApplicantModal(false)} />          </div>
        </div>
        <div className="delAccModalErrMsg" style={{ margin: 0, padding: 0 }}>
          <img src={deleteIcon2} alt="Delete Icon" />
          <p>Would you like to delete this applicant?</p>
          <div>
            <button
              style={{
                padding: "7px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid #007749",
                background: 'none',
                color: '#007749'
              }}
              onClick={() => setDeleteApplicantModal(false)}
            >
              Cancel
            </button>
            <button
              style={{
                padding: "7px 20px",
                margin: "10px",
                borderRadius: "5px",
                border: "none",
                color: "#FFF",
                backgroundColor: "#BC0000",
              }}
              onClick={handleConfirmDeleteUser}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={editApplicantModal}
        onRequestClose={() => setEditApplicantModal(false)}
        ariaHideApp={false}
        style={editApplicantStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="CmpnyDetailsCrossIcon" onClick={handleCloseApplicantModal}>x</div>
        <div className="CmpnyDetailTitle">Edit Applicant Details</div>
        <div className="editAppcntFieldsOuterDiv">
          {/* <div className="editAppcntNote">*Only Email Id and LinkedIn Profile fields are editable.</div> */}
          <div className="editAppcntFieldsRow">
            <div>
              <ComponentAndScreenConstant.InputTextBox
                placeholderText="Enter Applicant Name"
                label={'Applicant Name'}
                isRequired={true}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                value={editObj?.applicantName?.fieldValue}
                onTextChange={(e) => onTextChange("Applicant Name", e.target.value)}
                errormsg={editObj?.applicantName?.errorField}
                paddingLeftZeroRequired="true"
              />
            </div>
            <div>
              <ComponentAndScreenConstant.InputTextBox
                placeholderText="Enter Applicant Mobile No."
                label={'Mobile No.'}
                isRequired={false}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                value={editObj?.mno?.fieldValue}
                // onTextChange={(e) => onTextChange("No. of openings", e.target.value, 5)}
                // errormsg={basicInfo?.noOfOpenings?.errorField}
                paddingLeftZeroRequired="true"
                maxLength={10}
                // inputType="number"
                cursor="not-allowed"
              />
            </div>
          </div>

          <div className="editAppcntFieldsRow">
            <div>
              <ComponentAndScreenConstant.InputTextBox
                placeholderText="Enter Applicant Email Id"
                label={'Email Id'}
                isRequired={true}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                value={editObj?.emailId?.fieldValue}
                onTextChange={(e) => onTextChange("Email Id", e.target.value)}
                errormsg={editObj?.emailId?.errorField}
                paddingLeftZeroRequired="true"
              />
            </div>
            <div>
              <ComponentAndScreenConstant.InputTextBox
                placeholderText="Enter Applicant LinkedIn Profile URL"
                label={'Resume/LinkedIn Profile'}
                isRequired={true}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                value={editObj?.linkedinProfile?.fieldValue}
                onTextChange={(e) => onTextChange("Resume/LinkedIn Profile", e.target.value)}
                errormsg={editObj?.linkedinProfile?.errorField}
                paddingLeftZeroRequired="true"
              />
            </div>
          </div>
          
          <div className="editAppcntFieldsRow">
            <div>
              <ComponentAndScreenConstant.SelectPicker
                label={"Years of Experience"}
                isRequired={false}
                data={Experience?.map((k) => {
                  return { ...k, name: k?.experience, id: k.experienceId };
                })}
                onChange={(i, index) => {
                  console.log("selected", i.target.value, index)
                  setSelectedExperience(JSON.parse(i.target.value));
                }}
                rstSelector={rstSelector}
                value={selectedExperience?.experienceId}
                defaultValueToDisplay={selectedExperience?.experience ?? "Select Years of Experience"}
              />
            </div>
            <div>
            <ComponentAndScreenConstant.InputTextBox
                placeholderText="Enter Applicant Calendly Profile URL"
                label={'Calendly Profile'}
                isRequired={false}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                value={editObj?.calendlyProfile?.fieldValue}
                onTextChange={(e) => onTextChange("Calendly Profile", e.target.value)}
                errormsg={editObj?.calendlyProfile?.errorField}
                paddingLeftZeroRequired="true"
              />
            </div>
          </div>

          <div className="editAppcntFieldsRow">
            <ComponentAndScreenConstant.SelectField
              labelTxt="Keywords"
              placeholderTxt={"Select Keywords"}
              isMultiValue={true}
              menuPlacement={"top"}
              isCreatable={false}
              selectedValue={selectedKeywords}
              selectOptions={KeywordsSuggestions}
              onChange={(e) => { handleKeywordsChange(e) }}
              componentWidth="100%"
              inputWidth="100%"
              gap={true}
              isRequired={false}
              maxSelected = {10}
              selectID={'keywords'}
            />
          </div>

          <div className="editAppcntFieldsRow">
            <ComponentAndScreenConstant.SelectField
              labelTxt="Work Location"
              placeholderTxt={"Select Work Location"}
              isMultiValue={true}
              menuPlacement={"top"}
              isCreatable={false}
              selectedValue={selectedLocations}
              selectOptions={LocationSuggestions}
              onChange={(e) => { handleLocationChange(e) }}
              componentWidth="100%"
              inputWidth="100%"
              gap={true}
              isRequired={false}
              maxSelected = {5}
              selectID={'workLocation'}
            />
          </div>

        </div>
        <div className="editAppcntBtns">
          <button onClick={handleUpdateApplicant}>Update</button>
        </div>
      </Modal>

      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="ApplicantRepodelAccModal1">
          <div className="ApplicantRepodelAccModalCrossDiv">
            <p
              className="ApplicantRepodelAccModalCrossPara"
              onClick={closeFailModal}
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="ApplicantRepodelAccModalErrLottie"
          />
        </div>
        <div className="ApplicantRepodelAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
          <button className="modalOkBtn" onClick={closeFailModal}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={closeSuccessModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="ApplicantRepodelAccModal1">
          <div className="ApplicantRepodelAccModalCrossDiv">
            <p
              onClick={closeSuccessModal}
              className="ApplicantRepodelAccModalCrossPara"
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="ApplicantRepodelAccModalErrLottie"
          />
        </div>
        <div className="ApplicantRepodelAccModalErrMsg">{successMsg}</div>
        <div className="modalOkBtnDiv">
          <button className="modalOkBtn" onClick={closeSuccessModal}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
    </div>
  );
};

export default ApplicantRepository;
