import Login from '../screens/Auth/Login'
import LandingPage from '../screens/LandingPage/LandingPage'
import CreateJob from '../screens/CreateJob/CreateJob'
import DropdownWithSelectPicker from '../components/DropdownWithSelectPicker/DropdownWithSelectPicker'
import InputTextBox from '../components/InputTextBox/InputTextBox'
import SelectPicker from '../components/SelectPicker/SelectPicker'
import DatePickerComponent from '../components/DatePickerComponent/DatePickerComponent'
import TextBox from '../components/TextBox/TextBox'
import EditJob from '../screens/EditJob/EditJob'
import TablePagination from '../components/TablePagination/TablePagination'
import Register from '../screens/Auth/Register'
import Dashboard from '../screens/Dashboard/Dashboard'
import ApplicantAction from '../screens/ApplicantAction/ApplicantAction'
import ApplicantRepository from '../screens/ApplicantRepository/ApplicantRepository'
import Sidebar from '../components/Sidebar/Sidebar'
import PosterDashboard from '../screens/PosterDashboard/PosterDashboard'
import Notification from '../screens/Notification/Notification'
import DashBoardHeader from '../components/DashboardHeader/DashboardHeader'
import UserProfile from '../screens/Profile/UserProfile'
import JobDetails from '../screens/JobDetails/JobDetails'
import ManagementLandingPage from '../screens/ManagementLandingPage/ManagementLandingPage'
import EditTeamsJob from '../screens/EditTeamsJob/EditTeamsJob'
import ProfileTeammate from '../screens/ProfileTeammate/ProfileTeammate'
import UserManagement from '../screens/UserManagement/UserManagement'
import JobPostingRecruiterSummaryReport from '../screens/Reports/JobPostingRecruiterSummaryReport/JobPostingRecruiterSummaryReport'
import JobPostingSummaryAcrossCompanyReport from '../screens/Reports/JobPostingSummaryAcrossCompanyReport/JobPostingSummaryAcrossCompanyReport'
import JobPostingPerRecruiter from '../screens/Reports/JobPostingPerRecruiter/JobPostingPerRecruiter'
import ReportsDashboard from '../screens/Reports/ReportsDashboard/ReportsDashboard'
import TotalUsageSummaryReport from '../screens/Reports/TotalUsageSummaryReport/TotalUsageSummaryReport'
import ShortListed from '../screens/ShortListed/ShortListed' 
import CompanyList from '../screens/CompanyList/CompanyList'
import CompanyProfile from '../screens/CompanyProfile/CompanyProfile'
import SelectField from '../components/SelectField/SelectField'

export const ComponentAndScreenConstant = {

    // screens
    Login,
    LandingPage,
    CreateJob,
    EditJob,
    Register,
    Dashboard,
    ApplicantAction,
    ApplicantRepository,
    PosterDashboard,
    Notification,
    UserProfile,
    JobDetails,
    ManagementLandingPage,
    EditTeamsJob,
    ProfileTeammate,
    UserManagement,
    ReportsDashboard,
    JobPostingRecruiterSummaryReport,
    JobPostingSummaryAcrossCompanyReport,
    JobPostingPerRecruiter,
    TotalUsageSummaryReport,
    ShortListed,
    CompanyList,
    CompanyProfile,

    
    // components
    DropdownWithSelectPicker,
    InputTextBox,
    SelectPicker,
    DatePickerComponent,
    TextBox,
    TablePagination,
    Sidebar,
    DashBoardHeader,
    SelectField
}

