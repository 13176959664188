import React, { useState } from 'react'
import '../CompanyProfile/CompanyProfile.css'
import { useLocation } from 'react-router-dom'
import noCompanyLogo from '../../assets/nocompany.svg'
import { FaUserGroup } from "react-icons/fa6";

const CompanyProfile = () => {
  const propsData = useLocation()
  console.log("propsDataaaaaaaa",propsData)
  const [companyDetails,setCompanyDetails] = useState(propsData?.state?.companyInfo)
  return (
    <div className='companyProfileOuterDiv'>
        <div className='companyProfileDiv'>
            <div className='companyProfile'>
                <div className='companyProfileUpperPart'>
                <div className='companyLogoDiv'>
                    <img src={noCompanyLogo}/>
                </div>
                <div className='companyNameDiv' title={companyDetails?.companyName}>{companyDetails?.companyName}</div>
                <div className='companyAddressDiv'>{companyDetails?.industries[0]?.industryName}</div>
                <div className='companyAddressDiv'>{companyDetails?.companyAddress}</div>
                </div>
                <div className='companyProfileLowerPart'>
                <div className='ProfileLowerDiv2'>
                    Since, {companyDetails?.establishmentDate}
                  </div>
                  <div className='ProfileLowerDiv2'>
                  <FaUserGroup className='empSizeIcon'/>
                  <div>{companyDetails?.companySize?.companySize}</div>
                  </div>
                </div>
            </div>
            <div className='companyDetailsDiv'>
               <div className='companyProfileFieldDiv'>
                <div>Company Website Link:</div>
                <div title={companyDetails?.companyUrl} onClick={()=>window.open(companyDetails?.companyUrl,'_blank')}>{companyDetails?.companyUrl}</div>
               </div>
               {companyDetails?.linkedinUrl && <div className='companyProfileFieldDiv'>
                <div>LinkedIn URL:</div>
                <div title={companyDetails?.linkedinUrl} onClick={()=>window.open(companyDetails?.linkedinUrl,'_blank')}>{companyDetails?.linkedinUrl}</div>
               </div>}
               {companyDetails?.facebookUrl && <div className='companyProfileFieldDiv'>
                <div>Facebook:</div>
                <div title={companyDetails?.facebookUrl} onClick={()=>window.open(companyDetails?.facebookUrl,'_blank')}>{companyDetails?.facebookUrl}</div>
               </div>}
               {companyDetails?.instagramUrl && <div className='companyProfileFieldDiv'>
                <div>Instagram:</div>
                <div title={companyDetails?.instagramUrl} onClick={()=>window.open(companyDetails?.instagramUrl,'_blank')}>{companyDetails?.instagramUrl}</div>
               </div>}
               <div className='companyProfileServicesDiv'>
               <div>Services:</div>
               <div>
                {companyDetails?.services?.map((item,i)=>{
                  return(
                    <div>{item?.serviceName}{i!=(companyDetails?.services?.length)-1? ", " : "."}</div>
                  )
                })}
               </div>
               </div>
               <div className='companyProfileServicesDiv'>
               <div>Company Description:</div>
               <div>{companyDetails?.companyDescription}</div>
               </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyProfile