export const ProfileConstant = {
    DevRecruiterV1 :'DevRecruiterV1',
    IntRecruiterV1 :'IntRecruiterV1' ,
    UatRecruiterV1 :'UatRecruiterV1',
    ProdRecruiterV1:'ProdRecruiterV1',

    DevLeaderV1 :'DevLeaderV1',
    IntLeaderV1 :'IntLeaderV1' ,
    UatLeaderV1 :'UatLeaderV1',
    ProdLeaderV1:'ProdLeaderV1',

    DevAdminV1 :'DevAdminV1',
    IntAdminV1 :'IntAdminV1' ,
    UatAdminV1 :'UatAdminV1',
    ProdAdminV1:'ProdAdminV1',

}

export const RoleConstant = {
    ROLE_MANAGER :'ROLE_MANAGER',
    ROLE_RECRUTER :'ROLE_RECRUTER' ,
    ROLE_ADMIN :'ROLE_ADMIN' ,

}